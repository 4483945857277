import { NoticeModalProps } from "@components/organism/NoticeModal";
import { AlertModalProps } from "@components/template/AlertModal";
import globalModalState, { GlobalModalType } from "@recoil/globalModalState";
import { useSetRecoilState } from "recoil";

export default function useGlobalModal() {
  const setModal = useSetRecoilState(globalModalState);

  const showModal = ({ modalType, modalProps }: GlobalModalType) => {
    setModal({ modalType, modalProps });
  };
  const hideModal = () => {
    setModal({ modalType: null, modalProps: null });
  };

  const showAlert = ({
    title,
    message,
    buttonText,
    isLottie,
    isButtonDisabled,
    handleClose,
  }: AlertModalProps) => {
    showModal({
      modalType: "AlertModal",
      modalProps: {
        title,
        message,
        buttonText,
        isLottie,
        isButtonDisabled,
        handleClose: handleClose || (() => hideModal()),
      },
    });
  };

  const showNotice = ({ title, buttonText }: NoticeModalProps) => {
    showModal({
      modalType: "NoticeModal",
      modalProps: {
        title,
        buttonText,
        handleClose: () => hideModal(),
      },
    });
  };

  return {
    showModal,
    hideModal,
    showAlert,
    showNotice,
  };
}
