import { maxWidth } from "@styles/index";
import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import styled, { css, CSSObject } from "styled-components";

interface CommonWrapProps extends PropsWithChildren {
  style?: CSSObject;
  fullWidth?: boolean;
  fullHeight?: boolean;
  autoHeight?: boolean;
  width?: string;
  height?: string;
  id?: string;
}

/**
 * @param style?: CSSObject;
 * @param fullWidth?: boolean;
 * @param fullHeight?: boolean;
 * @param autoHeight?: boolean;
 * @param width?: string;
 * @param height?: string;
 */
const CommonWrap = forwardRef(
  (props: CommonWrapProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <StyledWrap id={props.id} ref={ref} style={props.style} {...props}>
        {props.children}
      </StyledWrap>
    );
  },
);

CommonWrap.displayName = "CommonWrap";

export default CommonWrap;

const StyledWrap = styled.div<CommonWrapProps>`
  ${({ width }) => {
    if (width) {
      return css`
        width: ${width};
      `;
    } else {
      return css`
        ${maxWidth};
        width: 100%;
      `;
    }
  }}
  height: ${({ height }) => (height ? height : "100%")};
`;
