import { colors, media, typo } from "@styles/index";
import { Radio, RadioChangeEvent } from "antd";
import React from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import Text, { textType } from "../Text";

interface CustomRadioProps {
  title: string;
  titleType?: textType;
  titleSize?: FlattenSimpleInterpolation;
  options: { label: JSX.Element | string; value: string }[];
  radioValue?: string;
  onChange?: (e: RadioChangeEvent) => void;
}

const CustomRadio: React.FC<CustomRadioProps> = ({
  title,
  titleType = "p",
  titleSize = typo.HEADING_06,
  options,
  radioValue,
  onChange,
}) => {
  return (
    <div>
      <Text
        style={{ marginBottom: 16 }}
        color={colors.GRAY_04}
        type={titleType}
        size={titleSize}
      >
        {title}
      </Text>
      <Radio.Group
        onChange={onChange}
        defaultValue={options?.[0]?.value ?? ""}
        value={radioValue}
      >
        <StyledRadioGroup>
          {options?.map(({ label, value }) => {
            return (
              <StyledRadio key={value} value={value}>
                {label}
              </StyledRadio>
            );
          })}
        </StyledRadioGroup>
      </Radio.Group>
    </div>
  );
};

export default CustomRadio;

const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.MOBILE} {
    .ant-radio {
      padding-bottom: 40px;
    }
  }
`;

const StyledRadio = styled(Radio)`
  color: ${colors.GRAY_02};
  ${typo.BODY_08R};
  display: inline-flex;
  align-items: center;

  .ant-radio {
    top: 0;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px #26b0eb22;
  }

  .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${colors.PRIMARY};
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;
  }

  .ant-radio-checked {
    ::after {
      border-color: ${colors.PRIMARY};
    }

    .ant-radio-input {
      :focus {
        border-color: ${colors.PRIMARY};
      }
    }

    .ant-radio-inner {
      background: ${colors.PRIMARY};
      border-color: ${colors.PRIMARY};

      ::after {
        border-color: ${colors.PRIMARY};
        background: ${colors.WHITE};
        left: 50%;
        top: 50%;
        transform: scale(0.5);
      }
    }
  }
`;
