import { requestTerms } from "@apis/index";
import { TermsCategory, TermsResponse } from "@apis/types";
import AntdSelect from "@components/atoms/AntdSelect";
import CommonWrap from "@components/atoms/CommonWrap";
import Text from "@components/atoms/Text";
import Layout from "@components/template/Layout";
import BaseSection from "@components/template/Layout/BaseSection";
import TitleWrap from "@components/TitleWrap";
import useRouter from "@hooks/useRouter";
import useWindowSize from "@hooks/useWindowSize";
import { colors, media, MOBILE_PADDING_HORIZON, typo } from "@styles/index";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

interface TermsProps {}

interface TermsLabel {
  value: string;
  label: string;
  disabled?: boolean;
}

// TODO selct로 데이터 바꾸는 거 해야됨
const termsTitleCheck = (termsCategory: TermsCategory) => {
  switch (termsCategory) {
    case "privacy-policy": {
      return "개인정보처리방침";
    }
    case "service-use-policy": {
      return "회원 이용약관";
    }
  }
};

const Terms: React.FC<TermsProps> = ({}) => {
  const { location } = useRouter();
  const { query } = useRouter();
  const termsCategory = query.category as TermsCategory;

  const [termsData, setTermsData] = useState<TermsResponse[]>([]);
  const [termsLabel, setTermsLabel] = useState<TermsLabel[] | null>(null);
  const [selectTerms, setSelectTerms] = useState<string>("");

  const { height: windowHeight } = useWindowSize();

  useQuery(
    ["terms", termsCategory, location],
    () => requestTerms(termsCategory),
    {
      onSuccess: (data) => {
        setTermsData(
          data.map((terms) => ({
            ...terms,
            postDate: terms.postDate.replaceAll("-", "."),
          })),
        );

        const label: TermsLabel[] = data.map((terms) => ({
          value: `${terms.id}`,
          label: terms.postDate.replaceAll("-", "."),
        }));
        setTermsLabel(label);
        setSelectTerms(data[0].detail);
      },
      onError: (e) => {
        // console.log("🧨 >>>> 조회실패", e);
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleChange = (value: any) => {
    const selectData = termsData.filter((terms) => terms.id === +value);
    setSelectTerms(selectData[0].detail);
  };

  useEffect(() => {
    setTermsData([]);
    setTermsLabel(null);
    setSelectTerms("");
  }, [location]);

  return (
    <Layout headerWhite>
      <StyledBaseSection fullWidth flexCenter>
        <ContentsWrap>
          <StyledTitleWrap gap="16px">
            <Title>{termsTitleCheck(termsCategory)}</Title>

            {termsLabel && (
              <AntdSelect
                onChange={handleChange}
                defaultValue={termsLabel[0].label}
                data={termsLabel}
              />
            )}
          </StyledTitleWrap>

          <ContentsBox
            windowHeight={windowHeight}
            dangerouslySetInnerHTML={{
              __html: `<div>${selectTerms}</div>`,
            }}
          />
        </ContentsWrap>
      </StyledBaseSection>
    </Layout>
  );
};

export default Terms;

const StyledBaseSection = styled(BaseSection)`
  ${media.MOBILE} {
    padding: 0 ${MOBILE_PADDING_HORIZON};
  }
`;

const ContentsWrap = styled(CommonWrap)`
  padding-top: 80px;
  padding-bottom: 200px;

  ${media.MOBILE} {
    padding-top: 32px;
    padding-bottom: 60px;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  padding-bottom: 60px;
  border-bottom: 1px solid ${colors.GRAY_09};

  ${media.MOBILE} {
    padding-bottom: 24px;
  }
`;

const Title = styled(Text)`
  ${typo.DISPLAY_05};

  ${media.MOBILE} {
    ${typo.HEADING_02}
  }
`;

const ContentsBox = styled.div<{ windowHeight: number }>`
  overflow: auto;
  white-space: pre-line;
  word-break: keep-all;
  padding: 60px 0;
  min-height: ${({ windowHeight }) => windowHeight && windowHeight * 0.4}px;

  ${media.MOBILE} {
    padding: 24px 0;
  }

  width: 100%;

  img {
    width: 100%;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 6px;
  }
  h3 {
    font-size: 18.72px;
    margin-bottom: 6px;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  strong {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }

  p {
    margin-bottom: 20px;
    b {
      margin-bottom: 20px;
    }
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  figure {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 30px 0;
    border: 1px solid rgb(209, 215, 237);

    tbody {
      tr {
        /* text-align: center; */
        vertical-align: bottom;
        padding: 10px;

        td {
          vertical-align: middle;
          padding: 10px;
          border: 1px solid rgb(209, 215, 237);
        }
      }
    }
  }
`;
