import { SubLabel } from "@components/atoms/CustomInput";
import Text from "@components/atoms/Text";
import { colors, media, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface RadioItemProps {
  label: string;
  subLabel?: string;
}

const RadioItem: React.FC<RadioItemProps> = ({ label, subLabel }) => {
  return (
    <Wrap>
      <Text type="p" size={typo.BODY_07M} color={colors.GRAY_02}>
        {label}
      </Text>
      <StyledSubLabel>{subLabel}</StyledSubLabel>
    </Wrap>
  );
};

export default RadioItem;
const Wrap = styled.div`
  display: flex;
  gap: 8px;

  ${media.MOBILE} {
    flex-direction: column;
  }
`;

const StyledSubLabel = styled(SubLabel)`
  ${media.MOBILE} {
    min-height: 36px;
  }
`;
