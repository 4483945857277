import { requestIRNotices } from "@apis/index";
import { NoticeResponse } from "@apis/types";
import CommonWrap from "@components/atoms/CommonWrap";
import Text from "@components/atoms/Text";
import ContentTitleLabel from "@components/molecule/ContentTitleLabel";
import Layout from "@components/template/Layout";
import BaseSection from "@components/template/Layout/BaseSection";
import useRouter from "@hooks/useRouter";
import noticeListState from "@recoil/noticeListState";
import {
  colors,
  media,
  MOBILE_PADDING_HORIZON,
  TABLET_PADDING_HORIZON,
  typo,
} from "@styles/index";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down-gray.svg";
import { Helmet } from "react-helmet-async";

const IRNotice: React.FC = () => {
  const { push } = useRouter();
  const [noticeList, setNoticeList] = useState<NoticeResponse[]>([]);

  const [page, setPage] = useState(1);
  const [{ pageSize }, setPageSize] = useRecoilState(noticeListState);
  const { data } = useQuery(
    ["noticeList", page],
    () => requestIRNotices({ page, pageSize }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (listData) => {
        setNoticeList((prev) => [...prev, ...listData.list]);
      },
    },
  );

  const goToDetailPage = (id: number) => {
    push(`/ir-notice/${id}`, {
      state: { noticeDetailId: id },
    });
    setPageSize({ pageSize: noticeList.length });
  };

  return (
    <>
      <Helmet>
        <title>신율농장 | 투자정보</title>
        <meta name="title" content="신율농장 투자정보 공지입니다." />
        <meta name="keywords" content="신율농장, 기업활동, IR, 투자정보" />
        <meta name="description" content="신율농장 투자정보 공지입니다." />
        <link rel="canonical" href="https://www.sinyul.co.kr/ir-notice" />
      </Helmet>
      <Layout headerWhite>
        <ContentTitleLabel
          title="투자정보 공지"
          description="신율농장의 기업활동 및 투자정보를 알려드립니다."
        />

        <ContentsSection fullWidth flexCenter>
          <ContentsWrap>
            <NoticeListWrap>
              {noticeList?.map(({ createdAt, title, id }) => {
                return (
                  <NoticeItem onClick={() => goToDetailPage(id)} key={id}>
                    <NoticeLabel size={typo.HEADING_05}>{title}</NoticeLabel>
                    <NoticeCreateAt size={typo.BODY_07R} color={colors.GRAY_04}>
                      {dayjs(createdAt).locale("ko").format("YYYY.MM.DD")}
                    </NoticeCreateAt>
                  </NoticeItem>
                );
              })}
            </NoticeListWrap>

            <StyledWrap>
              <StyledButton
                onClick={() => {
                  if (!data?.isLast && data?.nextPage) setPage(data.nextPage);
                }}
              >
                더보기{" "}
                <Text size={typo.BODY_07R} color={colors.PRIMARY}>
                  {noticeList.length}
                </Text>
                <Text size={typo.BODY_07R} color={colors.GRAY_06}>
                  / {data?.count}
                </Text>
                <span>
                  <ArrowIcon />
                </span>
              </StyledButton>
            </StyledWrap>
          </ContentsWrap>
        </ContentsSection>
      </Layout>
    </>
  );
};

export default IRNotice;

export const ContentsSection = styled(BaseSection)`
  padding: 0 ${TABLET_PADDING_HORIZON};
  height: auto;

  ${media.MOBILE} {
    padding: 0 ${MOBILE_PADDING_HORIZON};
  }
`;

export const ContentsWrap = styled(CommonWrap)`
  min-height: 1421px;
  padding-top: 80px;
  padding-bottom: 200px;

  ${media.MOBILE} {
    height: unset;
    min-height: 1000px;
    padding-top: unset;
    padding-bottom: 80px;
    max-width: unset;
  }
`;

export const NoticeListWrap = styled.ul``;

export const NoticeItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.GRAY_09};
  cursor: pointer;

  ${media.MOBILE} {
    padding: 16px 0;
  }
`;

export const NoticeLabel = styled(Text)`
  ${media.MOBILE} {
    ${typo.HEADING_07}
    word-break: keep-all;
  }
`;

export const NoticeCreateAt = styled(Text)`
  ${media.MOBILE} {
    ${typo.BODY_09R}
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const StyledButton = styled.button`
  background: ${colors.WHITE};
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 20px;
  color: ${colors.PRIMARY};
`;
