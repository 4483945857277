import { ICONS } from "@assets/index";
import {
  Error,
  Input,
  InputBox,
  SubLabel,
} from "@components/atoms/CustomInput";
import { colors, media, typo } from "@styles/index";
import { Select } from "antd";
import React, { HTMLInputTypeAttribute } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import Icon from "../../atoms/Icon";
import Text, { textType } from "../../atoms/Text";

interface EmailInputProps {
  label?: string;
  subLabel?: string;
  subLabelBold?: boolean;
  labelSize?: FlattenSimpleInterpolation;
  labelType?: textType;
  placeholder: string;
  placeholderSecond?: string;
  isAllowClear?: boolean;
  onChangeLocal?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeDomain?: (e: any) => void;
  nameLocal?: string;
  nameDomain?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?:
    | "text"
    | "search"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;

  [key: string]: any;
  valueLocal?: string;
  valueDomain?: string;
  maxLength?: number;
  defualtEmailLocalValue?: string;
  defualtEmailDomainValue?: string;

  error?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({
  label = "test",
  subLabel = "",
  subLabelBold,
  labelSize = typo.HEADING_06,
  labelType = "p",
  placeholder,
  placeholderSecond,
  defualtEmailLocalValue,
  defualtEmailDomainValue,
  error = "",
  inputMode,
  maxLength,
  nameLocal,
  isAllowClear,
  nameDomain,

  onChangeLocal,
  onChangeDomain,
  type = "text",
  valueLocal,
  valueDomain,
  ...res
}) => {
  const [isSelectIconAnimation, setIsSelectIconAnimation] =
    React.useState<boolean>(false);
  const options = [
    { label: "직접입력", value: "" },
    { label: "gmail.com", value: "gmail.com" },
    { label: "naver.com", value: "naver.com" },
    { label: "hanmail.net", value: "hanmail.net" },
    { label: "kakao.com", value: "kakao.com" },
  ];

  return (
    <InputBox>
      {label && (
        <Text color={colors.GRAY_04} type={labelType} size={labelSize}>
          {label}{" "}
          {subLabel && (
            <SubLabel subLabelBold={subLabelBold}>{subLabel}</SubLabel>
          )}
        </Text>
      )}
      <div>
        <InputWrap>
          <InputInnerWrap>
            <Input
              name={nameLocal}
              type={type}
              placeholder={placeholder}
              value={valueLocal}
              onChange={onChangeLocal}
              inputMode={inputMode}
              autoCapitalize="off"
              maxLength={maxLength}
              {...res}
            />
            <span>@</span>
            <Input
              name={nameDomain}
              type={type}
              value={valueDomain}
              placeholder={placeholderSecond}
              onChange={onChangeDomain}
              inputMode={inputMode}
              autoCapitalize="off"
              maxLength={maxLength}
              {...res}
            />
          </InputInnerWrap>

          <StyledSelect
            options={options}
            defaultValue={[options[0].value]}
            onChange={onChangeDomain}
            onClick={() => setIsSelectIconAnimation(!isSelectIconAnimation)}
            placeholder={placeholder ? placeholder : "placeholder"}
            notFoundContent={<>상위 카테고리를 선택해주세요</>}
            isSelectIconAnimation={isSelectIconAnimation}
          />
        </InputWrap>
        {error && (
          <Error>
            <Icon backgroundSrc={ICONS.WARNING_CIRCLE} width={16} height={16} />
            <Text type="p" size={typo.BODY_07R} color={colors.RED_01}>
              {error}
            </Text>
          </Error>
        )}
      </div>
    </InputBox>
  );
};

export default EmailInput;

const InputWrap = styled.div`
  /*  */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  ${media.MOBILE} {
    flex-direction: column;
  }
`;

const InputInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;

  ${media.MOBILE} {
    gap: 8px;
  }
`;

const StyledSelect = styled(Select)<{ isSelectIconAnimation?: boolean }>`
  height: 53px;
  max-width: 214px;
  width: 100%;
  ${typo.BODY_07M};

  ${media.MOBILE} {
    max-width: unset;
  }

  .ant-select-selector {
    height: 100% !important;
    border-radius: 12px !important;
    padding: 14px 20px !important;
  }

  .ant-select-selection-item {
    padding-right: 25px !important;
    line-height: 23px !important;
  }

  .ant-select-arrow {
    width: 24px;
    height: 24px;
    margin-top: 2px;
    top: 25%;
    right: 15px;
    background: no-repeat center url(${ICONS.FILL_ARROW}) !important;
    transition: all 0.3s ease-in-out;

    ${({ isSelectIconAnimation }) => {
      if (isSelectIconAnimation) {
        return css`
          transform: rotate(0deg);
        `;
      } else {
        return css`
          transform: rotate(180deg);
        `;
      }
    }}

    span {
      display: none;
    }
  }

  input {
    height: 100% !important;
  }

  :focus {
    border-color: rgb(38, 176, 235) !important;
  }

  :where(.css-dev-only-do-not-override-1vtf12y).ant-select:not(
      .ant-select-disabled
    ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: rgb(38, 176, 235) !important;
  }
`;
