import { ICONS } from "@assets/index";
import { colors, media, typo } from "@styles/index";
import React, { HTMLInputTypeAttribute } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import Icon from "../Icon";
import Text, { textType } from "../Text";

interface CustomInputProps {
  label?: string;
  subLabel?: string;
  subLabelBold?: boolean;
  flexBreakLabel?: boolean;
  labelSize?: FlattenSimpleInterpolation;
  labelType?: textType;
  placeholder: string;
  isAllowClear?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?:
    | "text"
    | "search"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;

  [key: string]: any;
  value?: string;
  maxLength?: number;
  defualtValue?: string;

  error?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label = "test",
  subLabel = "",
  subLabelBold,
  flexBreakLabel,
  labelSize = typo.HEADING_06,
  labelType = "p",
  placeholder,
  defualtValue,
  error = "",
  inputMode,
  isAllowClear,
  maxLength,
  name,
  onChange,
  type = "text",
  value,
  ...res
}) => {
  return (
    <InputBox>
      {label && (
        <StyledText
          color={colors.GRAY_04}
          type={labelType}
          size={labelSize}
          flexBreakLabel={flexBreakLabel}
        >
          {label}{" "}
          {subLabel && (
            <SubLabel subLabelBold={subLabelBold}>{subLabel}</SubLabel>
          )}
        </StyledText>
      )}
      <div>
        <div>
          <Input
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            defaultValue={defualtValue}
            onChange={onChange}
            inputMode={inputMode}
            autoCapitalize="off"
            maxLength={maxLength}
            {...res}
          />
        </div>
        {error && (
          <Error>
            <Icon backgroundSrc={ICONS.WARNING_CIRCLE} width={16} height={16} />
            <Text type="p" size={typo.BODY_07R} color={colors.RED_01}>
              {error}
            </Text>
          </Error>
        )}
      </div>
    </InputBox>
  );
};

export default CustomInput;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.MOBILE} {
    gap: 12px;
  }
`;

export const StyledText = styled(Text)<{ flexBreakLabel?: boolean }>`
  ${media.MOBILE} {
    display: flex;

    ${({ flexBreakLabel }) =>
      flexBreakLabel &&
      `
    flex-direction: column;
    `}
    gap: 8px;
    ${typo.HEADING_08}
  }
`;

export const Input = styled.input`
  padding: 14px 20px;
  width: 100%;
  border: 1px solid ${colors.GRAY_09};
  border-radius: 12px;
  border-color: ${colors.GRAY_08};
  ${typo.BODY_07R};
  outline: 0px solid transparent;

  ::placeholder {
    color: ${colors.GRAY_07};
    text-overflow: ellipsis;
    /* color: ${colors.GRAY_06}; */
  }

  :hover {
    border-color: rgb(38, 176, 235);
    transition: all 0.3s ease-in-out;
  }

  :focus {
    border-color: rgb(38, 176, 235);
  }

  :focus-visible {
    border-color: rgb(38, 176, 235);
    outline: 2px solid rgb(230, 244, 255);
    transition: all 0.3s ease-in;
  }

  :-ms-input-placeholder {
    /* IE 및 Edge */
    color: ${colors.GRAY_06};
  }

  ::-moz-placeholder {
    /* Firefox 18- */
    color: ${colors.GRAY_06};
    /* Firefox에서 불투명하게 표시하려면 필요 */
    opacity: 1;
  }

  ::-webkit-input-placeholder {
    /* Chrome, Safari, Opera */
    color: ${colors.GRAY_06};
  }
`;

export const SubLabel = styled.span<{ subLabelBold?: boolean }>`
  color: ${colors.GRAY_06};
  margin-left: 4px;
  ${typo.BODY_07R};

  ${({ subLabelBold }) =>
    subLabelBold &&
    `
    font-weight: 700;
  `}

  ${media.MOBILE} {
    ${typo.BODY_10R};
    ${({ subLabelBold }) =>
      subLabelBold &&
      `
    font-weight: 700;
  `}
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 8px;
  color: ${colors.RED_01};
  ${typo.BODY_10R};
  height: 18px;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
  }
`;
