import { Row } from "@apis/types";
import request from "@network/index";
import { PaymentMethod, PaymentMethodOption } from "@pages/CorporateSignup";

interface SignupRequest {
  corporateName: string;
  ownerName: string;
}

// 중복확인
export const requestCheckDuplicate = async (query: SignupRequest) => {
  const { data } = await request<
    SignupRequest,
    Row<{ isEligibleForSignup: boolean }>
  >({
    method: "get",
    url: "/homepage/corporate/check-duplicate",
    requestParams: query,
  });

  return data.row;
};

interface ResponsePDFUpload {
  filePath: string;
  fileName: string;
}

// 사업자 등록증 업로드
export const requestUpload = async (uploadData: File) => {
  const formData = new FormData();
  formData.append("businessRegistration", uploadData);

  const { data } = await request<FormData, Row<ResponsePDFUpload>>({
    method: "post",
    url: "/homepage/business-registration",
    requestBody: formData,
    isMultipart: true,
  });

  return data.row;
};

export const requestCorporateSignup = async (
  body: SignUpCorporateMemberRequestBody,
) => {
  await request<SignUpCorporateMemberRequestBody, void>({
    method: "post",
    url: "/homepage/corporate/sign-up",

    requestBody: body,
  });
};

export interface SignUpCorporateMemberRequestBody {
  loginId: string;
  // 아이디

  password: string;
  // 비밀번호

  name: string;
  // 회원명 (본인인증 결과)

  phone: string;
  // 휴대폰번호 (본인인증 결과)

  corporateName: string;
  // 법인명

  ownerName: string;
  // 대표자명

  businessNumber: string;
  // 사업자 등록번호

  businessRegistrationFileName: string;
  // 사업자 등록증 파일명 (PDF)

  businessRegistration: string;
  // 사업자 등록증 (PDF)

  email: string;
  // 이메일

  contact?: string;
  // 담당자 연락처

  paymentMethod: PaymentMethod;
  // 결제 방식

  paymentMethodOption?: PaymentMethodOption;
  // 사후 별도 정산 옵션
}

// 아이디 중복 확인
export const requestCheckDuplicateId = async (query: { loginId: string }) => {
  const { data } = await request<
    { loginId: string },
    Row<{ isAvailableId: boolean }>
  >({
    method: "get",
    url: "/homepage/unique-login-id",

    requestParams: query,
  });

  return data.row.isAvailableId;
};
