import CommonWrap from "@components/atoms/CommonWrap";
import Text from "@components/atoms/Text";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import TitleWrap from "@components/TitleWrap";
import { productCardList } from "@constants/home";
import { useIntersecting } from "@hooks/useIntersecting";
import useInterval from "@hooks/useInterval";
import { SubTitle, Title } from "@styles/CommonStyled";
import {
  colors,
  cssTemplate,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface Section05Props {
  id?: string;
}

const HomeSection05: React.FC<Section05Props> = ({ id }) => {
  const { ref: sectionRef, isIntersecting: isTitleIntersecting } =
    useIntersecting({
      options: { threshold: 0.3 },
    });
  const { ref: cardWrapRef, isIntersecting: isCardWrapIntersecting } =
    useIntersecting({
      options: { threshold: 0.4 },
    });

  useEffect(() => {
    if (isTitleIntersecting) setIsTitleAnimation(true);
  }, [isTitleIntersecting]);

  const [isTitleAnimation, setIsTitleAnimation] = useState<boolean>(false);

  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isCardWrapIntersecting
      ? isInteracted > productCardList.length
        ? null
        : 150
      : null,
  );

  const contentsData = [
    {
      label: "군밤",
      labelColor: colors.WHITE,
      labelBackgroundColor: colors.BLUE_03,
      contents: [
        {
          title: "군밤 2kg",
          description: `간편하게 쉽게 드실 수 있는 작은 용량의 밤으로 당일 구운 밤으로 보내 드립니다.`,
          imageUrl: "/images/pc2kg.webp",
          keyWord: "가격: 35,000원",
          keyWordBackgroundColor: colors.PURPLE_05,
          keyWordColor: colors.PURPLE,
        },
        {
          title: "군밤 4kg",
          description: `보관하면서 드실 수 있는 용량의 밤으로 밤 껍질을 제거한 후 냉동하여 드실때 전자렌지로 해동하면 갓 구운 밤 맛을 느끼실 수 있습니다.`,
          imageUrl: "/images/pc4kg.webp",
          keyWord: "가격: 60,000원",
          keyWordBackgroundColor: colors.PURPLE_05,
          keyWordColor: colors.PURPLE,
        },
      ],
    },
    {
      label: "생밤",
      labelColor: colors.GRAY_01,
      labelBackgroundColor: colors.ORANGE_03,
      contents: [
        {
          title: "생밤 2kg",
          description: `밤껍질이 있는 밤으로 냉장 보관하셔서 드셔고 좋고, 음식 만드실 때 넣어 드셔도 좋습니다.`,
          imageUrl: "/images/p2kg.webp",
          keyWord: "가격: 35,000원",
          keyWordBackgroundColor: colors.PURPLE_05,
          keyWordColor: colors.PURPLE,
        },
        {
          title: "생밤 4kg",
          description: `물기가 많고 단맛이 좋은 생밤으로 껍질채 냉장 보관하셔서 드셔도 좋고, 껍질을 깐 후 냉장 보관하셔도 좋습니다. 수분 증발이 되지 않도록 보관하세요.`,
          imageUrl: "/images/p4kg.webp",
          keyWord: "가격: 60,000원",
          keyWordBackgroundColor: colors.PURPLE_05,
          keyWordColor: colors.PURPLE,
        },
      ],
    },
  ];

  return (
    <StyledBaseSection ref={sectionRef} fullWidth flexCenter>
      <StyledCommonWrap theme={{ isAnimation: isTitleAnimation }}>
        <StyledTitleWrap id={id}>
          <SubTitle type="h3">신율 밤 구매</SubTitle>
          <ScreenResizing
            pc={
              <Title type="h2" whiteSpace="pre-line">
                {`고객이 안심하고 맛있게 드실 수 있도록\n최고품질의 밤을 선별하여 보내 드립니다`}
              </Title>
            }
            tablet={
              <Title type="h2" whiteSpace="pre-line">
                {`고객이 안심하고 맛있게 드실 수 있도록\n최고품질의 밤을 선별하여 보내 드립니다`}
                </Title>
            }
            mobile={
              <Title type="h2" whiteSpace="pre-line">
                {`고객이 안심하고 맛있게 드실 수 있도록\n최고품질의 밤을 선별하여 보내 드립니다`}
                </Title>
            }
          />
        </StyledTitleWrap>
        <ContentsWrap>
          {contentsData.map(
            ({ label, labelBackgroundColor, labelColor, contents }, idx) => {
              return (
                <CardWrap key={idx}>
                  <CardLabelWrap
                    backgroundColor={labelBackgroundColor}
                    color={labelColor}
                  >
                    <p>{label}</p>
                  </CardLabelWrap>

                  {contents.map(
                    ({
                      title,
                      description,
                      imageUrl,
                      keyWord,
                      keyWordBackgroundColor,
                      keyWordColor,
                    }) => {
                      return (
                        <CardBox key={title}>
                          <CardTitleWrap>
                            <CardTitle>{title}</CardTitle>
                            <CardKeyword
                              backgroundColor={keyWordBackgroundColor}
                              color={keyWordColor}
                            >
                              {keyWord}
                            </CardKeyword>
                          </CardTitleWrap>
                          <CardDisription>{description}</CardDisription>
                          <ImageBox imageUrl={imageUrl} />
                        </CardBox>
                      );
                    },
                  )}
                </CardWrap>
              );
            },
          )}
        </ContentsWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
};

export default HomeSection05;

const StyledBaseSection = styled(BaseSection)`
  background: ${colors.GRAY_11};

  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    height: auto;
    padding: 0 ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    height: auto;
    padding: 0 ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  gap: 80px;

  position: relative;
  /* padding: ${PC_PADDING_VERTICAL} 0; */

  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.TABLET} {
    gap: 60px;
    padding: ${TABLET_PADDING_VERTICAL} 0;
  }
  ${media.MOBILE} {
    gap: 40px;
    padding: ${MOBILE_PADDING_VERTICAL} 0;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  ${media.MOBILE} {
    gap: 8px;
  }
`;

const ContentsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 32px;

  ${media.TABLET} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
`;

const CardBox = styled.div`
  background: ${colors.WHITE};
  padding: 32px;
  width: 100%;
  max-width: 587px;
  height: 416px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.TABLET} {
    width: 100%;
    max-width: unset;
    height: auto;
  }

  ${media.MOBILE} {
    padding: 32px 20px;
  }
`;

const CardTitle = styled(Text)`
  ${typo.HEADING_02};

  ${media.MOBILE} {
    ${typo.HEADING_07};
  }
`;

const CardDisription = styled(Text)`
  ${typo.BODY_07R};
  color: ${colors.GRAY_06};
  white-space: pre-line;
  word-break: keep-all;
  margin-bottom: 24px;

  ${media.TABLET} {
    max-width: 500px;
  }

  ${media.MOBILE} {
    ${typo.BODY_09R};
    margin-bottom: 20px;
  }
`;

const ImageBox = styled.span<{ imageUrl: string }>`
  display: inline-block;
  max-width: 523px;
  width: 100%;
  height: 226px;
  border-radius: 12px;
  background: no-repeat center/cover url(${({ imageUrl }) => imageUrl});

  ${media.TABLET} {
    max-width: 100%;
    width: 100%;
    height: 292px;
  }

  ${media.MOBILE} {
    height: 127px;
  }
`;

const CardWrap = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  ${CardBox} + ${CardBox} {
    :before {
      content: "";
      display: block;
      width: 87%;
      height: 1px;
      background-color: ${colors.GRAY_09};
      position: absolute;
      top: 487px;
      left: 50%;
      transform: translateX(-50%);
    }

    ${media.TABLET} {
      :before {
        height: 1px;
        width: 91%;
        top: 555px;
      }
    }

    ${media.MOBILE} {
      :before {
        top: 377px;
      }
    }
  }
`;

const CardLabelWrap = styled.div<{ backgroundColor: string; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  ${typo.BODY_04M};
`;

const CardTitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
`;

const CardKeyword = styled.span<{ backgroundColor: string; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 6px;
  ${typo.BODY_10M}

  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;
