import { cssTemplate, grid, media } from "@styles/index";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";

interface ScreenResizingProps {
  pc?: React.ReactNode;
  pcPadding?: string;
  pcCenter?: boolean;

  tablet?: React.ReactNode;
  tabletPadding?: string;
  tabletCenter?: boolean;

  mobile?: React.ReactNode;
  mobilePadding?: string;
  mobileCenter?: boolean;
}

const ScreenResizing: React.FC<ScreenResizingProps> = (props) => {
  const isPc = useMediaQuery({ minWidth: grid.MAX_TABLET });
  const isTablet = useMediaQuery({
    minWidth: grid.MAX_MOBILE,
    maxWidth: grid.MAX_TABLET - 1,
  });
  const isMobile = useMediaQuery({ maxWidth: grid.MAX_MOBILE - 1 });

  return (
    <>
      {isPc && (
        <Pc className="PC" {...props}>
          {props.pc}
        </Pc>
      )}
      {isTablet && (
        <Tablet className="TABLET" {...props}>
          {props.tablet}
        </Tablet>
      )}
      {isMobile && (
        <Mobile className="MOBILE" {...props}>
          {props.mobile}
        </Mobile>
      )}
    </>
  );
};

export default ScreenResizing;

const Pc = styled.div<ScreenResizingProps>`
  width: 100%;
  ${({ pcPadding }) =>
    pcPadding &&
    css`
      padding: ${pcPadding};
    `}

  ${({ pcCenter }) =>
    pcCenter &&
    css`
      ${cssTemplate.flexCenter}
    `}
  ${media.TABLET} {
    display: none;
  }
`;

const Tablet = styled.div<ScreenResizingProps>`
  max-width: ${grid.MAX_TABLET};
  display: none;
  ${media.TABLET} {
    display: block;
    ${({ tabletPadding }) =>
      tabletPadding &&
      css`
        padding: ${tabletPadding};
      `}

    ${({ tabletCenter }) =>
      tabletCenter &&
      css`
        ${cssTemplate.flexCenter}
      `}
  }
  ${media.MOBILE} {
    display: none;
  }
`;

const Mobile = styled.div<ScreenResizingProps>`
  display: none;
  ${media.MOBILE} {
    display: block;
    ${({ mobilePadding }) =>
      mobilePadding &&
      css`
        padding: ${mobilePadding};
      `}

    ${({ mobileCenter }) =>
      mobileCenter &&
      css`
        ${cssTemplate.flexCenter}
      `}
  }
`;
