import { createGlobalStyle, css, keyframes } from "styled-components";
import reset from "styled-reset";

export const PC_PADDING_HORIZON = "200px";
export const MIN_PC_PADDING_HORIZON = "130px";
export const TABLET_PADDING_HORIZON = "48px";
export const MOBILE_PADDING_HORIZON = "20px";

export const PC_PADDING_VERTICAL = "160px";
export const TABLET_PADDING_VERTICAL = "120px";
export const MOBILE_PADDING_VERTICAL = "80px";

// export const tabletContainerGap = "6.25rem";
// export const pcContainerGap = "12.5rem";
// export const mobileContainerGap = "6.25rem";
// export const mobileContainerSmallGap = "5rem";

export const ANIMATION_COUNT_VALUE = 1800;

export const grid = {
  MAX: 1920,
  MIN_PC: 1440,
  MAX_TABLET: 1200, // FIXME 고민
  MAX_MOBILE: 768, // FIXME 고민
  MIN: 375,

  // MARGIN: 120,
  // MARGIN_LARGE: 360,
  // MARGIN_TABLET: 48,
  // MARGIN_MOBILE: 24,
};

export const media = {
  MIN_PC: `@media(max-width: ${grid.MIN_PC - 1}px)`,
  TABLET: `@media(max-width: ${grid.MAX_TABLET - 1}px)`,
  MOBILE: `@media(max-width: ${grid.MAX_MOBILE - 1}px)`,
};

export const zIndex = {
  HEADER: 60,
  HEADER_BUTTON_WRAP: 61,
  HEADER_INNER_BUTTON: 62,
  HEADER_OUT_BUTTON: 63,
  HEADER_SIDE_MENU: 59,
};

export const maxWidth = css`
  max-width: 75rem;
  ${media.TABLET} {
    max-width: 48rem;
  }
  ${media.MOBILE} {
    max-width: 23.4375rem;
  }
`;

export const colors = {
  PRIMARY: "#26B0EB",
  PRIMARY_01: "#008BC7",
  PRIMARY_02: "#0099DB",
  PRIMARY_03: "#6BD3FF",
  PRIMARY_04: "#A6E4FF",
  PRIMARY_05: "#BEECFF",
  PRIMARY_06: "#D8F3FF",
  PRIMARY_07: "#EDFAFF",
  PRIMARY_08: "#F7FDFF",

  GRAY_01: "#000000",
  GRAY_02: "#222729",
  GRAY_03: "#353C3F",
  GRAY_04: "#4A5256",
  GRAY_05: "#5E696E",
  GRAY_06: "#6E777C",
  GRAY_07: "#AEB7BC",
  GRAY_08: "#CFD6D9",
  GRAY_09: "#E7EDF0",
  GRAY_10: "#F1F4F6",
  GRAY_11: "#F7F9FA",
  WHITE: "#FFFFFF",

  RED: "#FF334B",
  RED_01: "#E5172F",
  RED_02: "#FF697A",
  RED_03: "#FF96A3",
  RED_04: "#FCC5CB",
  RED_05: "#FFEDEF",

  ORANGE: "#FF9500",
  ORANGE_01: "#E17700",
  ORANGE_02: "#FFAA33",
  ORANGE_03: "#FFBF66",
  ORANGE_04: "#FFDFB3",
  ORANGE_05: "#FFF4E6",

  YELLOW: "#FFCC00",
  YELLOW_01: "#E1AE00",
  YELLOW_02: "#FFD633",
  YELLOW_03: "#FFE066",
  YELLOW_04: "#FFF0B3",
  YELLOW_05: "#FFF7D9",

  GREEN: "#15BD66",
  GREEN_01: "#18A352",
  GREEN_02: "#35DB80",
  GREEN_03: "#64E8A4",
  GREEN_04: "#93EDBF",
  GREEN_05: "#EBFAEF",

  BLUE: "#007AFF",
  BLUE_01: "#005CE1",
  BLUE_02: "#3395FF",
  BLUE_03: "#66AFFF",
  BLUE_04: "#B3D7FF",
  BLUE_05: "#E6F2FF",

  PURPLE: "#8556E3",
  PURPLE_01: "#6B3CC9",
  PURPLE_02: "#A17DF5",
  PURPLE_03: "#BD9FF5",
  PURPLE_04: "#D4C4F5",
  PURPLE_05: "#F5EBFF",

  NUT_01: "#964939",
  NUT_02: "#FFEDEF",
};

export const cssTemplate = {
  commonTrasition: css`
    transition: 0.3s all;
  `,
  flexCenter: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  hideOnMobile: css`
    ${media.MOBILE} {
      display: none;
    }
  `,
  showOnMobile: css`
    display: none;
    ${media.MOBILE} {
      display: flex;
    }
  `,
  ellipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  hideScroll: css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  `,
  textDragDisable: css`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  `,
  imageDragDisable: css`
    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
  `,
  fullScreen: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  vwConvertPc: (px: number) => {
    return `${(px * 100) / grid.MAX}vw`;
  },
  vwConvertTablet: (px: number) => {
    return `${(px * 100) / grid.MAX_MOBILE}vw`;
  },
  vwConvertMobile: (px: number) => {
    return `${(px * 100) / grid.MIN}vw`;
  },
  fadeInOut: ({ theme }: any) => {
    if (theme.isIntersecting) {
      return css`
        opacity: 1;
        animation: ${animations.fadeInUp} ${theme.duration || "0.5s"} forwards;
        animation-delay: ${theme.delay || "0s"};
      `;
    } else {
      return css`
        opacity: 0;
        transition: 0.4s;
      `;
    }
  },
  fadeInDown: ({ theme }: any) => {
    if (theme.isIntersecting) {
      return css`
        opacity: 1;
        animation: ${animations.fadeInDown} ${theme.duration || "0.5s"} forwards;
        animation-delay: ${theme.delay || "0s"};
      `;
    } else {
      return css`
        opacity: 0;
        transition: 0.4s;
      `;
    }
  },
  customFadeInDown: ({ theme }: any) => {
    if (theme.isAnimation) {
      return css`
        opacity: 1;
        animation: ${animations.fadeInUp} 1s forwards;
      `;
    } else {
      return css`
        opacity: 0;
      `;
    }
  },
};

export const typo = {
  DISPLAY_01: css`
    font-weight: 700;
    font-size: 64px;
    line-height: 68px;
  `,
  DISPLAY_02: css`
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;
  `,
  DISPLAY_03: css`
    font-weight: 700;
    font-size: 48px;
    line-height: 68px;
  `,
  DISPLAY_04: css`
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
  `,
  DISPLAY_05: css`
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
  `,

  HEADING_01: css`
    font-weight: 700;
    font-size: 26px;
    line-height: 38px;
  `,
  HEADING_02: css`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  `,
  HEADING_03: css`
    font-weight: 700;
    font-size: 22px;
    line-height: 34px; // FIXME 36.2992px 둘다 사용됨.
  `,
  HEADING_04: css`
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  `,
  HEADING_05: css`
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
  `,
  HEADING_06: css`
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
  `,
  HEADING_07: css`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  `,
  HEADING_08: css`
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
  `,
  HEADING_09: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  `,
  HEADING_10: css`
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
  `,
  HEADING_11: css`
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
  `,

  BODY_01M: css`
    font-weight: 500;
    font-size: 26px;
    line-height: 38px;
  `,
  BODY_01R: css`
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
  `,
  BODY_02M: css`
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  `,
  BODY_02R: css`
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  `,
  BODY_03M: css`
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
  `,
  BODY_03R: css`
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
  `,
  BODY_04M: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  `,
  BODY_04R: css`
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  `,
  BODY_05M: css`
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
  `,
  BODY_05R: css`
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
  `,
  BODY_06M: css`
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  `,
  BODY_06R: css`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  `,
  BODY_07M: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
  BODY_07R: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  BODY_08M: css`
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  `,
  BODY_08R: css`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  `,
  BODY_09M: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  `,
  BODY_09R: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  BODY_10M: css`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
  `,
  BODY_10R: css`
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  `,
  BODY_11M: css`
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  `,
  BODY_11R: css`
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  `,

  PARAGRAPH_01: css`
    font-weight: 400;
    font-size: 24px;
    line-height: 39.6px;
  `,
  PARAGRAPH_02: css`
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
  `,
  PARAGRAPH_03: css`
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  `,
  BUTTON_01B: css`
    font-weight: 600;
    font-size: 19px;
    line-height: 26px;
  `,
  BUTTON_01M: css`
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
  `,
  BUTTON_02B: css`
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  `,
  BUTTON_02M: css`
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  `,
  BUTTON_03B: css`
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  `,
  BUTTON_03M: css`
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  `,
  BUTTON_04B: css`
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  `,
  BUTTON_04M: css`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
  `,
  BUTTON_05B: css`
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
  `,
  BUTTON_05M: css`
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  `,
};

export const animations = {
  expandDown: keyframes`
    from {
      height: 0vh;
    }
    to {
      height: calc(var(--vh, 1vh) * 100);;
    }
  `,
  expandUp: keyframes`
    from {
      height: calc(var(--vh, 1vh) * 100);;
  }
    to {
      height: 0vh;
    }
  `,
  fadeInUp: keyframes`
    from {
      transform: translateY(20%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  `,
  mobileFadeInUp: keyframes`
  from {
      transform: translateY(30%);
      opacity: 1;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  `,
  fadeInDown: keyframes`
    from {
      transform: translateY(-30%);
      /* opacity: 0; */
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  `,
  fadeIn: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  fadeOut: keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `,
  firstArrow: keyframes`
    from {
      transform: translate(20%, -40%);
      opacity: 0;
    }
    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }`,
  secondArrow: keyframes`
    from {
      transform: translate(-20%, 40%);
      opacity: 0;
    }
    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }`,
  bannerFadeIn: keyframes`
  0% {
      transform: translateX(0%);
    }
100% {
      transform: translateX(100%);
    }
    `,
  onlySection02PcTextFadeIn: keyframes`
    from {
      transform: translate(0%, 20%);
      opacity: 0;
    }
    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  `,
  onlySection02TabletTextFadeIn: keyframes`
    from {
      transform: translate(0, 20%);
      opacity: 0;
    }
    to {
      transform: translate(0, 0%);
      opacity: 1;
    }
    `,
  sideMenuOpenAnimation: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
      height: 10rem;
    }
  `,
  sideMenuCloseAnimation: keyframes`
    from {
      opacity: 1;
      height: 10rem;
    }
    to {
      height: 0;
      padding: 0;
    }
  `,
};

export const shadow = {
  DP_1: css`
    box-shadow: 0rem 0.125rem 0.0175rem rgba(53, 58, 63, 0.04);
  `,
  DP_2: css`
    box-shadow: 0rem 0.125rem 1rem rgba(53, 58, 63, 0.04);
  `,
  DP_4: css`
    box-shadow: 0rem 0.125rem 1.25rem rgba(53, 58, 63, 0.08);
  `,
  DP_8: css`
    box-shadow: 0rem 0.25rem 2rem rgba(53, 58, 63, 0.1);
  `,
  DP_16: css`
    box-shadow: -1rem 1rem 2.5rem rgba(24, 48, 72, 0.16);
  `,
  DP_24: css`
    box-shadow: 0 0.5rem 2rem rgba(53, 58, 63, 0.24);
  `,
};

const antdSelectGlobalStyle = css`
  :where(.css-dev-only-do-not-override-1vtf12y).ant-select-dropdown
    .ant-select-item {
    min-height: 3rem;
    align-items: center;
    ${typo.BODY_06R}
    color: ${colors.GRAY_06};
  }
`;

export const GlobalStyle = createGlobalStyle`

${reset}

html,body {
  font-family: "Pretendard", sans-serif;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

:root {
  --vh: 100%;
}
height: calc(var(--vh, 1vh) * 100);

${antdSelectGlobalStyle}
`;
