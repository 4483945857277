import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { TechCardList } from "@constants/home";
import { colors, media, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface TechCardProps {
  data: TechCardList;
}

const TechCard: React.FC<TechCardProps> = ({ data }) => {
  const { iconSrc, title, description } = data;
  return (
    <StyledCard>
      <ScreenResizing
        pc={<Icon backgroundSrc={iconSrc} width={200} height={120} />}
        tablet={<Icon backgroundSrc={iconSrc} width={200} height={120} />}
        // tablet={<Icon backgroundSrc={iconSrc} width={68} height={68} />}
        mobile={<Icon backgroundSrc={iconSrc} width={150} height={100} />}
        // mobile={<Icon backgroundSrc={iconSrc} width={48} height={48} />}
      />
      <div>
        <CardTitle>{title}</CardTitle>
        <CardDescription wordBreak="keep-all">{description}</CardDescription>
      </div>
    </StyledCard>
  );
};

export default TechCard;

const StyledCard = styled.li`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 340px;
  min-height: 304px;
  justify-content: space-between;
  padding: 40px;
  border-radius: 24px;
  background-color: white;

  ${media.TABLET} {
    width: 280px;
    min-height: unset;
    padding: 24px;
  }
`;

const CardTitle = styled(Text)`
  ${typo.DISPLAY_05}
  margin-bottom: 16px;

  ${media.TABLET} {
    ${typo.HEADING_05}
    margin-bottom: 8px;
  }
`;

const CardDescription = styled(Text)`
  color: ${colors.GRAY_06};
  ${typo.BODY_07R};

  ${media.TABLET} {
    ${typo.BODY_09R}
  }
`;
