import HomeSection01 from "@components/organism/HomeSection01";
import HomeSection02 from "@components/organism/HomeSection02";
import HomeSection03 from "@components/organism/HomeSection03";
import HomeSection04 from "@components/organism/HomeSection04";
import HomeSection05 from "@components/organism/HomeSection05";
import HomeSection06 from "@components/organism/HomeSection06";
import HomeSection07 from "@components/organism/HomeSection07";
import HomeSection08 from "@components/organism/HomeSection08";
import HomeSection09 from "@components/organism/HomeSection09";
import HomeSection10 from "@components/organism/HomeSection10";
import Layout from "@components/template/Layout";
import { useIntersecting } from "@hooks/useIntersecting";
import noticeListState from "@recoil/noticeListState";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSetRecoilState } from "recoil";

export type MenuItem = "compony" | "service" | "produce" | "install";

const Home: React.FC = () => {
  const resetNoticeList = useSetRecoilState(noticeListState);
  useEffect(() => {
    resetNoticeList({ pageSize: 10 });
  }, [resetNoticeList]);

  const [activeMenu, setActiveMenu] = useState<MenuItem>("compony");
  // console.log("✅ >>>> MENU ACTIVE : ", activeMenu);

  const { ref: componyRef } = useIntersecting({
    menuItem: "compony",
    setState: setActiveMenu,
    options: {
      threshold: 0.35,
    },
  });

  const { ref: serviceRef } = useIntersecting({
    menuItem: "service",
    setState: setActiveMenu,
    options: {
      threshold: 0,
    },
  });
  const { ref: produceRef } = useIntersecting({
    menuItem: "produce",
    setState: setActiveMenu,
    options: {
      threshold: 0,
    },
  });

  const { ref: installRef } = useIntersecting({
    menuItem: "install",
    setState: setActiveMenu,
    options: {
      threshold: 0.7,
    },
  });

  return (
    <Layout activeMenu={activeMenu}>
      <div>
        <Helmet>
          <meta name="description" content="신율농장 소개" />
          <meta name="keyword" content="신율농장, 농장소개, 신율농장 안내" />
        </Helmet>
        {/* 배너 */}
        <HomeSection01 ref={componyRef} id="compony" />
        {/* 신율농장은 끊임없이... */}
        {/* <HomeSection02 /> */}
        {/* 앞서가는 품질과 맛으로... */}
        {/* <HomeSection03 /> */}
      </div>

      <div ref={serviceRef}>
        <Helmet>
          <meta name="description" content="맛과 품질" />
          <meta
            name="keyword"
            content="유기농 밤, 신지식인, 공주밤, 정안밤"
          />
        </Helmet>

        {/* 밤 판매 */}
        <HomeSection05 />

        {/* 더욱 편리한 스마트 모바일... */}
        {/* <HomeSection04 ref={serviceRef} id="service" /> */}

        {/* 국내 최고 품질의 충전기를 자체 개발하여... */}
        {/* <HomeSection07 /> */}

        {/* 지속적인 연구를 통해... */}
        <HomeSection06 />
      </div>

      <div ref={produceRef}>
        <Helmet>
          <meta name="description" content="밤 판매" />
          <meta
            name="keyword"
            content="대표사례, 신율농장, 공주밤 판매, 유기농 밤"
          />
        </Helmet>

        {/* 공간의 가치를 높여줄 최상의 충전환경을 디자인합니다 */}
        {/* <HomeSection08 id="produce" /> */}

        {/* 국내 최고의 파트너와 전국... */}
        {/* <HomeSection09 /> */}
      </div>

      <div>
        <Helmet>
          <meta name="description" content="설치문의" />
          <meta name="keyword" content="설치문의, , 설치문의 안내" />
        </Helmet>
        {/* 전기차 충전기 설치를 희망하신다면... */}
        <HomeSection10 ref={installRef} id="install" />
      </div>
    </Layout>
  );
};

export default Home;
