import { requestNoticeDetail } from "@apis/index";
import Button from "@components/atoms/Button";
import CommonWrap from "@components/atoms/CommonWrap";
import Text from "@components/atoms/Text";
import Layout from "@components/template/Layout";
import BaseSection from "@components/template/Layout/BaseSection";
import TitleWrap from "@components/TitleWrap";
import useRouter from "@hooks/useRouter";
import {
  colors,
  media,
  MOBILE_PADDING_HORIZON,
  TABLET_PADDING_HORIZON,
  typo,
} from "@styles/index";
import dayjs from "dayjs";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import styled from "styled-components";

const IRNoticeDetail: React.FC = () => {
  const { push, state, pathname } = useRouter();
  const detailId = pathname?.split("/").pop() || state.noticeDetailId;

  const { data } = useQuery(
    ["noticeDetail", detailId],
    () => requestNoticeDetail(detailId),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const createAt = dayjs(data?.createdAt).format("YYYY.MM.DD");

  return (
    <>
      <Helmet>

      </Helmet>
      <Layout headerWhite>
        <StyledBaseSection fullWidth flexCenter>
          <ContentsWrap>
            <StyledTitleWrap gap="6px">
              <Title>{data?.title}</Title>
              <CreateAt>{createAt}</CreateAt>
            </StyledTitleWrap>

            <ContentsBox
              dangerouslySetInnerHTML={{
                __html: `<div>
              ${data?.content}
              </div>`,
              }}
            />

            <BackButton
              pcBtnSize="extra"
              btnColor="lightGray"
              onClick={() => push("/ir-notice")}
            >
              목록으로 돌아가기
            </BackButton>
          </ContentsWrap>
        </StyledBaseSection>
      </Layout>
    </>
  );
};

export default IRNoticeDetail;

const StyledBaseSection = styled(BaseSection)`
  ${media.TABLET} {
    padding: 0 ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    padding: 0 ${MOBILE_PADDING_HORIZON};
  }
`;

const ContentsWrap = styled(CommonWrap)`
  padding-top: 80px;
  padding-bottom: 200px;

  ${media.TABLET} {
    padding-top: 80px;
    padding-bottom: 80px;

    max-width: unset;
  }
  ${media.MOBILE} {
    padding-top: 32px;
    padding-bottom: 60px;
  }

  img {
    width: 100%;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 6px;
  }
  h3 {
    font-size: 18.72px;
    margin-bottom: 6px;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  strong {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  figure {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 30px 0;
    border: 1px solid rgb(209, 215, 237);

    tbody {
      tr {
        /* text-align: center; */
        vertical-align: bottom;
        padding: 10px;

        td {
          vertical-align: middle;
          padding: 10px;
          border: 1px solid rgb(209, 215, 237);
        }
      }
    }
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  padding-bottom: 60px;
  border-bottom: 1px solid ${colors.GRAY_09};

  ${media.MOBILE} {
    padding-bottom: 24px;
  }
`;

const Title = styled(Text)`
  ${typo.DISPLAY_05};
  word-break: keep-all;

  ${media.MOBILE} {
    ${typo.HEADING_04}
  }
`;

const CreateAt = styled(Text)`
  ${typo.BODY_06R};
  color: ${colors.GRAY_06};

  ${media.MOBILE} {
    ${typo.BODY_09R}
  }
`;

const ContentsBox = styled.div`
  /* FIXME 데이터 확인해서, fix 해야될듯 */
  overflow: auto;
  padding: 60px 0;
  border-bottom: 1px solid ${colors.GRAY_09};
  margin-bottom: 60px;

  ${media.MOBILE} {
    margin-bottom: 24px;
    padding: 24px 0;
  }
`;

const BackButton = styled(Button)`
  ${media.TABLET} {
    padding: 15px 20px;
    ${typo.BUTTON_02B};
    border-radius: 8px;
  }
  ${media.MOBILE} {
    padding: 7px 10px;
    ${typo.BUTTON_04B};
    border-radius: 8px;
  }
`;
