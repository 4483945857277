import { ICONS } from "@assets/index";
import Button from "@components/atoms/Button";
import { StyledText, SubLabel } from "@components/atoms/CustomInput";
import Icon from "@components/atoms/Icon";
import Text, { textType } from "@components/atoms/Text";
import { colors, grid, media, typo } from "@styles/index";
import React, { HTMLInputTypeAttribute } from "react";
import { useMediaQuery } from "react-responsive";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

interface FileInputProps {
  label?: string;
  subLabel?: string;
  flexBreakLabel?: boolean;
  subLabelBold?: boolean;
  labelSize?: FlattenSimpleInterpolation;
  labelType?: textType;
  placeholder: string;
  isAllowClear?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?:
    | "text"
    | "search"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;

  [key: string]: any;
  value?: string;
  maxLength?: number;
  defualtValue?: string;

  error?: string;
}

const FileInput: React.FC<FileInputProps> = ({
  label = "업로드",
  subLabel,
  subLabelBold,
  flexBreakLabel,
  labelSize = typo.HEADING_06,
  labelType = "p",
  placeholder,
  defualtValue,
  error = "",
  inputMode,
  isAllowClear,
  maxLength,
  name,
  onChange,
  type = "text",
  value,
  ...res
}) => {
  const isMobile = useMediaQuery({ maxWidth: grid.MAX_MOBILE - 1 });
  return (
    <InputBox>
      {label && (
        <StyledText
          flexBreakLabel={flexBreakLabel}
          color={colors.GRAY_04}
          type={labelType}
          size={labelSize}
        >
          {label}{" "}
          {subLabel && (
            <SubLabel subLabelBold={subLabelBold}>{subLabel}</SubLabel>
          )}
        </StyledText>
      )}
      <div>
        <InputInnerBox isMobile={isMobile}>
          <FileNameDiv style={{ flex: 3 }} {...res}>
            {value ? value : placeholder}
          </FileNameDiv>
          <ButtonWrap>
            <Button
              style={{ width: "100%" }}
              btnType="line"
              pcBtnSize="extra"
              btnText="파일 찾기"
            />
            <File type="file" onChange={onChange} />
          </ButtonWrap>
        </InputInnerBox>
        {error && (
          <Error type="p" size={typo.BODY_07R} color={colors.RED_01}>
            <Icon backgroundSrc={ICONS.WARNING_CIRCLE} width={16} height={16} />
            <span>{error}</span>
          </Error>
        )}
      </div>
    </InputBox>
  );
};

export default FileInput;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputInnerBox = styled.div<{ isMobile?: boolean }>`
  display: flex;
  gap: 16px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
    `}
`;

const FileNameDiv = styled.div`
  padding: 14px 20px;
  width: 100%;
  border: 1px solid ${colors.GRAY_09};
  border-radius: 12px;
  border-color: ${colors.GRAY_08};
  ${typo.BODY_07R};
  outline: 0px solid transparent;
  color: ${colors.GRAY_06};

  /* ::placeholder { */
  /* color: ${colors.GRAY_07}; */
  /* color: ${colors.GRAY_06}; */
  /* } */
  /*  */
  /* :hover { */
  /* border-color: rgb(38, 176, 235); */
  /* transition: all 0.3s ease-in-out; */
  /* } */
  /*  */
  /* :focus { */
  /* border-color: rgb(38, 176, 235); */
  /* } */
  /*  */
  /* :focus-visible { */
  /* border-color: rgb(38, 176, 235); */
  /* outline: 2px solid rgb(230, 244, 255); */
  /* transition: all 0.3s ease-in; */
  /* } */
  /*  */
  /* :-ms-input-placeholder { */
  /* IE 및 Edge */
  /* color: ${colors.GRAY_06}; */
  /* } */
  /*  */
  /* ::-moz-placeholder { */
  /* Firefox 18- */
  /* color: ${colors.GRAY_06}; */
  /* Firefox에서 불투명하게 표시하려면 필요 */
  /* opacity: 1; */
  /* } */
  /*  */
  /* ::-webkit-input-placeholder { */
  /* Chrome, Safari, Opera */
  /* color: ${colors.GRAY_06}; */
  /* } */
`;

const Error = styled(Text)`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 8px;
  color: ${colors.RED_01};
  ${typo.BODY_10R};
  height: 18px;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
  }
`;

const ButtonWrap = styled.div`
  flex: 1;
  position: relative;
  max-width: 190px;
  min-width: 115px;
  width: 100%;

  ${media.MOBILE} {
    max-width: unset;
  }
`;

const File = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
