import Footer from "@components/organism/Footer";
import Header from "@components/organism/Header";
import { MenuItem } from "@pages/Home";
import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

interface LayoutProps extends PropsWithChildren {
  headerWhite?: boolean;
  activeMenu?: MenuItem;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  headerWhite,
  activeMenu,
}) => {
  return (
    <>
      <Header headerWhite={headerWhite} activeMenu={activeMenu} />
      <Body headerWhite={headerWhite}>{children}</Body>
      <Footer />
    </>
  );
};

export default Layout;

const Body = styled.main<{ headerWhite?: boolean }>`
  box-sizing: border-box;
  ${({ headerWhite }) =>
    headerWhite &&
    css`
      padding-top: 80px;
    `}
`;
