import { ICONS } from "@assets/index";
import { colors, media, typo } from "@styles/index";
import { Select } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import React from "react";
import styled from "styled-components";

interface AntdSelectProps {
  onChange?:
    | ((
        value: unknown,
        option:
          | DefaultOptionType
          | BaseOptionType
          | (DefaultOptionType | BaseOptionType)[],
      ) => void)
    | undefined;
  data?: (DefaultOptionType | BaseOptionType)[] | undefined;
  defaultValue?: any;
}

const AntdSelect: React.FC<AntdSelectProps> = ({
  onChange,
  data,
  defaultValue,
}) => {
  return (
    <StyledSelect
      autoFocus={false}
      defaultValue={defaultValue}
      style={{ width: 168 }}
      onChange={onChange}
      options={data}
    />
  );
};

export default AntdSelect;

const StyledSelect = styled(Select)`
  /* BUG style 부분적으로 안먹혀서 안먹히는 부분은 전역적으로 스타일 정의함. */

  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.MOBILE} {
    width: 100% !important;
    border-radius: 12px;
    overflow: hidden;
  }

  /* 배경 */
  :where(.css-dev-only-do-not-override-1vtf12y).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    height: 100%;
    background-color: ${colors.GRAY_11};
    padding: 0 16px;
  }

  /* 보더 라인 */
  :where(.css-dev-only-do-not-override-1vtf12y).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: none;
  }

  /* 텍스트 */
  :where(
      .css-dev-only-do-not-override-1vtf12y
    ).ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  :where(
      .css-dev-only-do-not-override-1vtf12y
    ).ant-select-single.ant-select-show-arrow
    .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    ${typo.BODY_06R}
    color: ${colors.GRAY_06};
  }

  /* icon */
  :where(.css-dev-only-do-not-override-1vtf12y).ant-select
    .ant-select-arrow
    .anticon {
    vertical-align: top;
    transition: transform 0.3s;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: no-repeat center/cover url(${ICONS.FILL_ARROW_DOWN});

    > svg {
      display: none;
    }
  }
`;
