import useWindowSize from "@hooks/useWindowSize";
import { grid } from "@styles/index";
import isMobileCheck from "@utils/isMobile";
import { forwardRef, PropsWithChildren } from "react";
import styled, { css, CSSObject } from "styled-components";

interface BaseSectionProps extends PropsWithChildren {
  style?: CSSObject;
  fullWidth?: boolean;
  fullHeight?: boolean;
  autoHeight?: boolean;
  width?: string;
  height?: string;
  flexCenter?: boolean;
  id?: string;
}

interface StyeledSectionType extends BaseSectionProps {
  windowHeight: number;
}

/**
 * @param style?: CSSObject;
 * @param fullWidth?: boolean;
 * @param fullHeight?: boolean;
 * @param autoHeight?: boolean;
 * @param width?: string;
 * @param height?: string;
 * @param flexCenter?: boolean;
 */

const BaseSection = forwardRef<any, BaseSectionProps>((props, ref) => {
  const { height } = useWindowSize();

  return (
    <StyledSection id={props.id} windowHeight={height} ref={ref} {...props}>
      {props.children}
    </StyledSection>
  );
});

BaseSection.displayName = "BaseSection";

export default BaseSection;

const StyledSection = styled.section<StyeledSectionType>`
  width: ${({ width }) => (width ? width : `${grid.MAX}px`)};
  height: ${({ autoHeight, height }) => (autoHeight ? "auto" : height)};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ fullHeight, windowHeight }) => {
    if (fullHeight && isMobileCheck())
      return css`
        height: 100vh;
      `;

    if (fullHeight) {
      return css`
        height: ${windowHeight}px;
      `;
    }
  }}

  position: relative;
  margin: auto;

  ${({ flexCenter }) =>
    flexCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;
