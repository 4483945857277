import CommonWrap from "@components/atoms/CommonWrap";
import Text from "@components/atoms/Text";
import BaseSection from "@components/template/Layout/BaseSection";
import TitleWrap from "@components/TitleWrap";
import { Title } from "@styles/CommonStyled";
import {
  colors,
  media,
  MOBILE_PADDING_HORIZON,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import React from "react";
import styled from "styled-components";

interface ContentTitleLabelProps {
  title: string;
  description: string;
  containerHeight?: string;
  titleGap?: string;
}

const ContentTitleLabel: React.FC<ContentTitleLabelProps> = ({
  title,
  description,
  containerHeight = "304px",
  titleGap = "8px",
}) => {
  return (
    <StyledBaseSection fullWidth height={containerHeight} flexCenter>
      <StyledCommonWrap>
        <TitleWrap gap={titleGap}>
          <StyledTitle>{title}</StyledTitle>
          <Description color={colors.GRAY_04}>{description}</Description>
        </TitleWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
};

export default ContentTitleLabel;

export const StyledBaseSection = styled(BaseSection)`
  background: ${colors.GRAY_10};
  height: 304px;

  ${media.TABLET} {
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }

  ${media.MOBILE} {
    height: 124px;
    padding: 0 ${MOBILE_PADDING_HORIZON};
  }
`;

export const StyledCommonWrap = styled(CommonWrap)`
  height: auto;
`;

export const StyledTitle = styled(Title)`
  ${media.MOBILE} {
    ${typo.HEADING_02}
  }
`;

export const Description = styled(Text)`
  ${media.MOBILE} {
    ${typo.BODY_09R}
  }
`;

export const ContentsSection = styled(BaseSection)`
  padding: 0 ${MOBILE_PADDING_HORIZON};
  height: auto;
`;

export const ContentsWrap = styled(CommonWrap)`
  min-height: 1421px;
  padding-top: 80px;
  padding-bottom: 200px;

  ${media.MOBILE} {
    height: unset;
    min-height: 1000px;
    padding-top: unset;
    padding-bottom: 80px;
    max-width: unset;
  }
`;

export const NoticeListWrap = styled.ul``;

export const NoticeItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.GRAY_09};
  cursor: pointer;

  ${media.MOBILE} {
    padding: 16px 0;
  }
`;

export const NoticeLabel = styled(Text)`
  ${media.MOBILE} {
    ${typo.HEADING_07}
    word-break: keep-all;
  }
`;

export const NoticeCreateAt = styled(Text)`
  ${media.MOBILE} {
    ${typo.BODY_09R}
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const StyledButton = styled.button`
  background: ${colors.WHITE};
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 20px;
  color: ${colors.PRIMARY};
`;
