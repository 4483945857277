import {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";

interface FaqProps {
  clientName: string;
  phoneNumber: string;
  email: string;
  address: string;
  contents: string;
}

const mockFaq = async (
  req: RestRequest<FaqProps, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
) => {
  const { address, contents, email, clientName, phoneNumber } = req.body;

  const isBodyValusCheck =
    !address || !contents || !email || !clientName || !phoneNumber;

  if (isBodyValusCheck)
    return res(
      ctx.status(400),
      ctx.json({
        message: "필수값을 전부 입력해주세요.",
      }),
    );

  return res(
    ctx.status(200),
    ctx.json({
      message: "succesful",
    }),
  );
};

export { mockFaq };
