import { colors, typo } from "@styles/index";
import React, { forwardRef, PropsWithChildren } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

type WhiteSpace = "pre" | "pre-line" | "pre-wrap";
type WordBreak = "break-all" | "keep-all";

export type textType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

interface TextProps extends PropsWithChildren {
  className?: string;
  color?: string;
  size?: FlattenSimpleInterpolation;
  whiteSpace?: WhiteSpace;
  wordBreak?: WordBreak;
  style?: React.CSSProperties;
  onClick?: () => void;
  textCenter?: boolean;
  id?: string;
  type?: textType;
}

const Text = forwardRef<any, TextProps>((props, ref) => {
  switch (props.type) {
    case "h1": {
      return (
        <StyledH1
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledH1>
      );
    }
    case "h2": {
      return (
        <StyledH2
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledH2>
      );
    }
    case "h3": {
      return (
        <StyledH3
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledH3>
      );
    }
    case "h4": {
      return (
        <StyledH4
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledH4>
      );
    }
    case "h5": {
      return (
        <StyledH5
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledH5>
      );
    }
    case "h6": {
      return (
        <StyledH6
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledH6>
      );
    }
    case "p": {
      return (
        <StyledP
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledP>
      );
    }
    default: {
      return (
        <StyledP
          ref={ref}
          id={props.id}
          className={props.className}
          onClick={props.onClick}
          style={props.style}
          {...props}
        >
          {props.children}
        </StyledP>
      );
    }
  }
});

Text.displayName = "Text";

export default Text;

const StyledH1 = styled.h1<TextProps>`
  ${({ size }) => (size ? size : `${typo.BODY_05M}`)};
  color: ${({ color }) => (color ? color : `${colors.GRAY_02}`)};

  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "normal")};
  word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : "normal")};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;
const StyledH2 = styled.h2<TextProps>`
  ${({ size }) => (size ? size : `${typo.BODY_05M}`)};
  color: ${({ color }) => (color ? color : `${colors.GRAY_02}`)};

  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "normal")};
  word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : "normal")};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;
const StyledH3 = styled.h3<TextProps>`
  ${({ size }) => (size ? size : `${typo.BODY_05M}`)};
  color: ${({ color }) => (color ? color : `${colors.GRAY_02}`)};

  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "normal")};
  word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : "normal")};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;
const StyledH4 = styled.h4<TextProps>`
  ${({ size }) => (size ? size : `${typo.BODY_05M}`)};
  color: ${({ color }) => (color ? color : `${colors.GRAY_02}`)};

  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "normal")};
  word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : "normal")};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;
const StyledH5 = styled.h5<TextProps>`
  ${({ size }) => (size ? size : `${typo.BODY_05M}`)};
  color: ${({ color }) => (color ? color : `${colors.GRAY_02}`)};

  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "normal")};
  word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : "normal")};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;
const StyledH6 = styled.h6<TextProps>`
  ${({ size }) => (size ? size : `${typo.BODY_05M}`)};
  color: ${({ color }) => (color ? color : `${colors.GRAY_02}`)};

  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "normal")};
  word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : "normal")};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;

const StyledP = styled.p<TextProps>`
  ${({ size }) => (size ? size : `${typo.BODY_05M}`)};
  color: ${({ color }) => (color ? color : `${colors.GRAY_02}`)};

  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "normal")};
  word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : "normal")};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `};
`;
