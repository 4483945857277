import { atom } from "recoil";

export interface noticeListStateType {
  pageSize: number;
}

const noticeListState = atom<noticeListStateType>({
  key: "noticeListState",
  default: {
    pageSize: 10,
  },
});

export default noticeListState;
