export const footerList = {
  notice: {
    contents: [
      {
        content: "공지사항",
        link: "/notice",
      },
      {
        content: "회원 이용약관",
        link: "/terms/records?category=service-use-policy",
      },
      {
        content: "개인정보처리방침",
        link: "/terms/records?category=privacy-policy",
      },
    ],
  },
  businessInfo: [
    {
      index: 0,
      title: "신율농장",
      pcInfo: {
        part1: [
          { title: "대표자", content: "이관재" },
          {
            title: "임업멘토",
            content: "한국임업진흥원(KOFPI)  |  ",
          },
          {
            title: "국가인증스타팜",
            content: "국립농산물품질관리원(제4-021호)",
          },
        ],
        part2: [
          {
            title: "주소",
            content: "32512 충청남도 공주시 정안면 고잿골길 102-1",
          },
        ],
        part3: [
          {
            title: "전화",
            content: "041-858-6242",
          },
        ],
      },
      tabletInfo: {
        part1: [
          { title: "대표자", content: "이관재" },
          {
            title: "임업멘토",
            content: "한국임업진흥원(KOFPI)  |  ",
          },
          {
            title: "국가인증스타팜",
            content: "국립농산물품질관리원(제4-021호)",
          },
        ],
        part2: [
          {
            title: "주소",
            content: "32512 충청남도 공주시 정안면 고잿골길 102-1",
          },
          {
            title: "전화",
            content: "041-858-6242",
          },
        ],
      },
      mobileInfo: `대표자 이관재 | 임업멘토: 한국임업진흥원(KOFPI) | 국가인증스타팜: 국립농산물품질관리원(제4-021호) | 주소 32512 충청남도 공주시 정안면 고잿골길 102-1 | 전화 041-858-6242`,
    },
  ],
  rightContents: [
    {
      title: "홈페이지",
      content: "https://www.albam.co.kr",
    },
    {
      title: "E-Mail",
      content: "newchestnut@hanmail.net",
    },
    {
      title: "밤 주문",
      content: "041-858-6242",
    },
  ],
};
