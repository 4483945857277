import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ErrorResponseType, RequestParams } from "./types";

const request = async <R, T>({
  method,
  url,
  requestBody,
  requestParams,
  isMultipart,
}: RequestParams<R>): Promise<AxiosResponse<T>> => {
  const HOST_URL =
    process.env.REACT_APP_ENV === "local" ? "/" : process.env.REACT_APP_API_URL;

  const axiosInstance = axios.create({
    baseURL: HOST_URL, // MSW Mock sever
    // baseURL: process.env.REACT_APP_API_URL,
  });

  // 헤더 설정
  const config: AxiosRequestConfig = {
    headers: {},
    params: requestParams,
  };

  if (isMultipart) {
    config.headers = {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    };
  }

  // REQUEST Interceptor
  axiosInstance.interceptors.request.use((interceptorsConfig) => {
    const { headers } = interceptorsConfig;

    console.log(
      "📕 REQ",
      interceptorsConfig.url,
      interceptorsConfig.method,
      interceptorsConfig.data,
      interceptorsConfig.params,
    );

    return interceptorsConfig;
  });

  // RESPONSE Interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      const { headers } = response;
      return response;
    },
    (error: AxiosError) => {
      // console.log("NET WORK ERROR", error);
      if (axios.isAxiosError(error)) {
        const { response } = error;
        const { data }: { data: ErrorResponseType } = response as AxiosResponse<
          any,
          any
        >;
        const commonError: ErrorResponseType = {
          error: data?.error,
          message: data?.message,
          statusCode: data?.statusCode,
        };
        return Promise.reject(commonError);
      }
      return Promise.reject(error);
    },
  );

  switch (method) {
    case "get":
      return axiosInstance.get(url, config);
    case "post":
      return axiosInstance.post(url, requestBody, config);
    case "put":
      return axiosInstance.put(url, requestBody, config);
    case "delete":
      return axiosInstance.delete(url, {
        data: requestBody, // FIXME
      });
    default:
      return Promise.reject(new Error("Invalid HttpMethod"));
  }
};

export default request;
