import { ICONS } from "@assets/index";
import Icon from "@components/atoms/Icon";
import { useBodyScrollLock } from "@hooks/useBodyScrollLock";
import { media } from "@styles/index";
import React, { useEffect } from "react";
import ReactModal, { Styles } from "react-modal";
import styled, { css } from "styled-components";

interface GlobalBaseLayoutModalProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  modalPaddingDisabled?: boolean;
  innerWrapStyle?: React.CSSProperties;
  unActiveScrollLock?: boolean;
  handleModalClose?: () => void;
}

const GlobalBaseLayoutModal: React.FC<GlobalBaseLayoutModalProps> = ({
  handleModalClose,
  children,
  innerWrapStyle,
  isOpen = false,
  modalPaddingDisabled,
  unActiveScrollLock,
  ...res
}) => {
  const { lockScroll, openScroll } = useBodyScrollLock();

  useEffect(() => {
    if (unActiveScrollLock) return;
    if (isOpen) {
      lockScroll();
    } else {
      openScroll();
    }
  }, [isOpen, lockScroll, openScroll, unActiveScrollLock]);

  const customStyles: Styles = {
    overlay: {
      background: "#00000040",
      zIndex: 9999,
    },
  };
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      style={customStyles}
      ariaHideApp={false}
      modalPaddingDisabled={modalPaddingDisabled}
      {...res}
    >
      <StyledDiv modalPaddingDisabled={modalPaddingDisabled}>
        <StyledIcon
          onClick={handleModalClose}
          backgroundSrc={ICONS.CLOSE}
          width={32}
          height={32}
        />
      </StyledDiv>
      <ModalInnerWrap style={innerWrapStyle}>{children}</ModalInnerWrap>
    </StyledModal>
  );
};

export default GlobalBaseLayoutModal;

const StyledModal = styled(ReactModal)<{
  modalPaddingDisabled?: boolean;
}>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 560px;
  width: 100%;
  height: auto;
  background: white;
  padding: ${({ modalPaddingDisabled }) =>
    modalPaddingDisabled ? "24px 0 0 0" : "24px"};
  border-radius: 16px;
  box-shadow: 0px 4px 32px rgba(53, 58, 63, 0.1);
  overflow: hidden;

  :focus {
    outline: none;
  }

  ${media.MOBILE} {
    max-width: unset;
    border-radius: unset;
    height: 100%;
  }
`;

const StyledDiv = styled.div<{
  modalPaddingDisabled?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  ${({ modalPaddingDisabled }) =>
    modalPaddingDisabled &&
    css`
      padding: 0 24px;
    `};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const ModalInnerWrap = styled.div`
  height: 100%;
  overflow: auto;

  ${media.MOBILE} {
    justify-content: space-around !important;
    padding: unset !important;
  }
`;
