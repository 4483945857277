import { ICONS } from "@assets/index";
import Button from "@components/atoms/Button";
import Icon from "@components/atoms/Icon";
import TitleWrap from "@components/TitleWrap";
import { ModalTitle } from "@styles/CommonStyled";
import { grid, media } from "@styles/index";
import ReactModal from "react-modal";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";

export interface NoticeModalProps {
  title?: string;
  message?: string;
  buttonText?: string;
  handleClose?: () => void;
}

function NoticeModal({
  title = "접수가 완료되었어요!",
  buttonText = "확인 ",
  handleClose,
}: NoticeModalProps) {
  const isMobile = useMediaQuery({ maxWidth: grid.MAX_MOBILE - 1 });
  return (
    <StyledModal isOpen onRequestClose={handleClose}>
      {!isMobile && (
        <StyledDiv>
          <StyledIcon
            onClick={handleClose}
            backgroundSrc={ICONS.CLOSE}
            width={32}
            height={32}
          />
        </StyledDiv>
      )}
      <StyledTitleWrap>
        <ModalTitle>{title}</ModalTitle>
      </StyledTitleWrap>
      <div style={{ display: "flex" }}>
        <Button style={{ flex: 1 }} pcBtnSize="extra" onClick={handleClose}>
          {buttonText}
        </Button>
      </div>
    </StyledModal>
  );
}

export default NoticeModal;

const StyledModal = styled(ReactModal)<{
  modalPaddingDisabled?: boolean;
}>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 560px;
  width: 100%;
  height: auto;
  background: white;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px rgba(53, 58, 63, 0.1);
  overflow: hidden;

  :focus {
    outline: none;
  }

  ${media.MOBILE} {
    max-width: 335px;
    padding: 24px;
  }
`;

const StyledDiv = styled.div<{
  modalPaddingDisabled?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledTitleWrap = styled(TitleWrap)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  ${media.MOBILE} {
    justify-content: unset;
    padding-bottom: 24px;
  }
`;
