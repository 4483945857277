import { useCallback, useEffect, useState } from "react";

interface WindowSize {
  width: number;
  height: number;
}

// TODO ref의 값을 측정하는 hook도 만들면 좋을꺼 같다.
// FIXME mobile 리사이즈 하는 거 막기.

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const updateWindowSize = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowSize({ width, height });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, [updateWindowSize]);

  return windowSize;
}

export default useWindowSize;
