import { NoticeModalProps } from "@components/organism/NoticeModal";
import { AlertModalProps } from "@components/template/AlertModal";
import { atom } from "recoil";

export interface GlobalModalType {
  modalType: "AlertModal" | "NoticeModal" | null;
  modalProps: AlertModalProps | NoticeModalProps | null;
}

const globalModalState = atom<GlobalModalType>({
  key: "globalModal",
  default: {
    modalType: null,
    modalProps: null,
  },
});

export default globalModalState;
