import NoticeModal from "@components/organism/NoticeModal";
import AlertModal from "@components/template/AlertModal";
import { useBodyScrollLock } from "@hooks/useBodyScrollLock";
import globalModalState from "@recoil/globalModalState";

import { useEffect } from "react";
import { useRecoilValue } from "recoil";

const MODAL_TYPES = {
  AlertModal: "AlertModal",
  NoticeModal: "NoticeModal",
} as const;

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.AlertModal]: AlertModal,
  [MODAL_TYPES.NoticeModal]: NoticeModal,
};

function GlobalModal() {
  const { modalProps, modalType } = useRecoilValue(globalModalState);
  const { lockScroll, openScroll } = useBodyScrollLock();
  const isScroll = document.body.style.overflow;

  useEffect(() => {
    if (modalType) {
      lockScroll();
    } else {
      openScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }
    const ModalComponent = MODAL_COMPONENTS[modalType];
    return <ModalComponent {...modalProps} />;
  };

  return <>{renderComponent()}</>;
}

export default GlobalModal;
