import Button from "@components/atoms/Button";
import Lottie from "@components/atoms/Lottie";
import GlobalBaseLayoutModal from "@components/template/GlobalBaseLayoutModal";
import { media, typo } from "@styles/index";
import { ModalDescription, ModalTitle } from "@styles/CommonStyled";
import styled from "styled-components";
import TitleWrap from "@components/TitleWrap";

export interface AlertModalProps {
  title?: string;
  message?: string;
  buttonText?: string;
  isLottie?: boolean;
  isButtonDisabled?: boolean;
  handleClose?: () => void;
}

function AlertModal({
  title = "접수가 완료되었어요!",
  message = `작성하신 문의 사항은 문자로 확인할 수 있으며,\n답변은 입력하신 연락처로 3~5일 내에 담당자가 직접 연락드려요.`,
  buttonText = "확인 ",
  isLottie = true,
  isButtonDisabled = false,
  handleClose,
}: AlertModalProps) {
  const innerWrapStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    gap: "40px",
    padding: "0 16px 16px",
  };
  return (
    <GlobalBaseLayoutModal
      isOpen
      handleModalClose={handleClose}
      innerWrapStyle={innerWrapStyle}
    >
      <StyledTitleWrap>
        {isLottie && (
          <LottieWrap>
            <Lottie keepLooping={false} lottieName="check" />
          </LottieWrap>
        )}
        <ModalTitle whiteSpace="pre-line">{title}</ModalTitle>
        <StyledModalDescription whiteSpace="pre-line">
          {message}
        </StyledModalDescription>
      </StyledTitleWrap>
      {!isButtonDisabled && (
        <Button pcBtnSize="extra" onClick={handleClose}>
          {buttonText}
        </Button>
      )}
    </GlobalBaseLayoutModal>
  );
}

export default AlertModal;

const StyledTitleWrap = styled(TitleWrap)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.MOBILE} {
    justify-content: unset;
  }
`;
const LottieWrap = styled.div`
  width: 120px;
  height: 120px;
`;
const StyledModalDescription = styled(ModalDescription)`
  ${typo.BODY_06R}
`;
