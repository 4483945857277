import { RecrutingType } from "@components/organism/RecruitingModal";
import request from "@network/index";
import {
  NoticeDetailResponse,
  NoticeResponse,
  PageNationType,
  Row,
  Rows,
  TermsCategory,
  TermsResponse,
  TermsResquest,
} from "./types/index";

/** 약관 조회 */
export const requestTerms = async (query: TermsCategory) => {
  const { data } = await request<TermsResquest, Rows<TermsResponse>>({
    method: "get",
    url: `/homepage/terms/records`,
    requestParams: {
      category: query,
    },
  });

  return data?.rows;
};

// 공지사항 리스트 조회
export const requestNotices = async (props: PageNationType) => {
  const { data } = await request<PageNationType, Rows<NoticeResponse>>({
    method: "get",
    url: `/homepage/notices?category=NORMAL`,
    requestParams: {
      page: props.page,
      pageSize: props.pageSize,
    },
  });

  return {
    count: data.count,
    list: data.rows,
    isLast: data.rows.length < props.pageSize,
    nextPage: props.page + 1,
  };
};

// 공지사항 상세조회
export const requestNoticeDetail = async (query: number) => {
  const { data } = await request<number, Row<NoticeDetailResponse>>({
    method: "get",
    url: `/homepage/notices/detail/${query}`,
  });

  return data.row;
};

// 투자정보(IR) 리스트 조회
export const requestIRNotices = async (props: PageNationType) => {
  const { data } = await request<PageNationType, Rows<NoticeResponse>>({
    method: "get",
    url: `/homepage/notices?category=IR`,
    requestParams: {
      page: props.page,
      pageSize: props.pageSize,
    },
  });

  return {
    count: data.count,
    list: data.rows,
    isLast: data.rows.length < props.pageSize,
    nextPage: props.page + 1,
  };
};

// 충전기 설치 문의 등록
export const requestFaq = async (body: RecrutingType) => {
  const { data } = await request({
    method: "post",
    url: "/homepage/installation",
    requestBody: body,
  });

  return data;
};
