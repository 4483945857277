import { MenuItem } from "@pages/Home";
import { useEffect, useRef, useState } from "react";

interface UseIntersectingProps {
  options?: IntersectionObserverInit;
  setState?: React.Dispatch<React.SetStateAction<MenuItem>>;
  menuItem?: MenuItem;
}

export const useIntersecting = ({
  options,
  menuItem,
  setState,
}: UseIntersectingProps) => {
  const ref = useRef<any>(null);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const [arrayEntries, setEntries] = useState<IntersectionObserverEntry[]>();
  const [isIntersecting, setIsIntersecting] = useState<boolean>();

  useEffect(() => {
    const newObserver = new IntersectionObserver(
      (entries) => setEntries(entries),
      options,
    );

    setObserver(newObserver);

    return () => {
      newObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef && observer) {
      observer.observe(currentRef);

      return () => observer.unobserve(currentRef);
    }
  }, [observer, ref]);

  useEffect(() => {
    if (arrayEntries) {
      arrayEntries.forEach((entry) => {
        if (entry.target === ref.current) {
          setIsIntersecting(entry.isIntersecting);
        }
      });
    }
  }, [arrayEntries]);

  /** Menu Active State - scroll animation */
  useEffect(() => {
    if (menuItem && setState) {
      if (isIntersecting) {
        // setState(menuItem);
        setState((prevState) => {
          if (prevState !== menuItem) {
            return menuItem;
          }

          return prevState;
        });
      }
    }
  }, [isIntersecting, menuItem, setState]);

  return {
    isIntersecting,
    ref,
  };
};
