import React, { forwardRef, PropsWithChildren } from "react";
import styled from "styled-components";

interface TitleWrapProps extends PropsWithChildren {
  gap?: string;
  id?: string;
}

const TitleWrap = forwardRef<any, TitleWrapProps>((props, ref) => {
  return (
    <StyledTitleWrap id={props.id} ref={ref} gap={props.gap} {...props}>
      {props.children}
    </StyledTitleWrap>
  );
});

TitleWrap.displayName = "TitleWrap";

export default TitleWrap;

const StyledTitleWrap = styled.div<TitleWrapProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "16px")};
`;
