import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

interface LottieProps {
  lottieName: string;
  width?: number;
  height?: number;
  keepLooping?: boolean;
}

/**
 * @param lottieName string => lottie의 json 파일명
 * @param width number
 * @param height number
 */
const Lottie: React.FC<LottieProps> = ({
  lottieName,
  width,
  height,
  keepLooping = true,
  ...res
}) => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const LottieRef = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      container: lottieContainer.current,
      renderer: "svg",
      loop: keepLooping,
      autoplay: true,
      path: `/lotties/${lottieName}.json`, // the path to the animation json
    });

    return () => LottieRef.destroy();
  }, [keepLooping, lottieName]);
  return (
    <LottieContainer
      width={width}
      height={height}
      ref={lottieContainer}
      {...res}
    />
  );
};

export default Lottie;

const LottieContainer = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${({ width }) => (width ? width : "100%")}px;
  height: ${({ height }) => (height ? height : "100%")}px;
`;
