import CommonWrap from "@components/atoms/CommonWrap";
import TechCard from "@components/molecule/TechCard";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import TitleWrap from "@components/TitleWrap";
import { techCardList } from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { SubTitle, Title } from "@styles/CommonStyled";
import {
  colors,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
} from "@styles/index";
import React from "react";
import styled from "styled-components";

interface HomeSection06Props extends AnimationCommonProps {}

const HomeSection06: React.FC<HomeSection06Props> = ({ id }) => {
  const listData = [
    ...techCardList,
    ...techCardList,
    ...techCardList,
    ...techCardList,
  ];

  return (
    <StyledBaseSection id={id} fullWidth height="918px" flexCenter>
      <StyledCommonWrap>
        <StyledTitleWrap>
          <SubTitle type="h3">맛있고 영양가 높은 밤</SubTitle>
          <ScreenResizing
            pc={
              <Title type="h2" whiteSpace="pre-line">
                {`맛과 영양, 건강과 즐거움으로...`}
              </Title>
            }
            tablet={
              <Title type="h2" whiteSpace="pre-line">
                {`맛과 영양, 건강과 즐거움으로...`}
              </Title>
            }
            mobile={
              <Title type="h2" whiteSpace="pre-line">
                {`맛과 영양, 건강과 즐거움으로...`}
              </Title>
            }
          />
        </StyledTitleWrap>
      </StyledCommonWrap>

      <CardListWrap>
        <CardListContainer speed={15.5}>
          {listData.map((data, idx) => {
            return <TechCard key={`${data.title}_${idx}`} data={data} />;
          })}
        </CardListContainer>
      </CardListWrap>
    </StyledBaseSection>
  );
};

export default HomeSection06;

const StyledBaseSection = styled(BaseSection)`
  background: ${colors.GRAY_11};
  overflow: hidden;

  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }
  ${media.TABLET} {
    height: 692px;
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    height: 518px;
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  position: relative;
`;

const StyledTitleWrap = styled(TitleWrap)`
  /*  */
  ${media.MOBILE} {
    gap: 8px;
  }
`;

const CardListWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  bottom: 196px;
  transform: translate(-50%, 0px);
  width: auto;
  overflow: hidden;

  ${media.TABLET} {
    gap: 16px;
    bottom: 120px;
    left: 50%;
  }

  ${media.MOBILE} {
    bottom: 80px;
  }
`;

const CardListContainer = styled.ul<{ speed: number }>`
  display: flex;
  width: 100%;
  gap: 32px;

  ${media.TABLET} {
    gap: 16px;
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-25%);
    }
  }
  animation: marquee ${({ speed }) => speed && speed * 5}s infinite linear;

  &:hover {
    animation-play-state: paused;
  }
`;
