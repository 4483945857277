import { colors, cssTemplate, media, typo } from "@styles/index";
import React, { PropsWithChildren } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export type BtnColor = "deepGray" | "lightGray" | "primary" | "white" | "black";

export type BtnSize = "extra" | "lager" | "medium" | "regular" | "small";

export type Width = number | "fit-content" | "auto";

export type BtnType = "line" | "fill";

export interface ButtonProps extends PropsWithChildren {
  btnText?: string;
  btnColor?: BtnColor;
  pcBtnSize?: BtnSize;
  mobilBtnSize?: BtnSize;
  radiusFull?: boolean;
  textSize?: FlattenSimpleInterpolation;
  padding?: string;
  width?: Width;
  disabled?: boolean;
  style?: React.CSSProperties;
  btnType?: BtnType;
  onClick?: () => void;

  [key: string]: any;
}

const Button: React.FC<ButtonProps> = ({
  btnText,
  btnColor = "primary",
  pcBtnSize,
  mobilBtnSize,
  radiusFull = false,
  textSize,
  padding,
  width,
  children,
  disabled,
  style,
  btnType,
  onClick,
  ...res
}) => {
  return (
    <>
      <StyledButton
        style={style}
        color={btnColor}
        pcSize={pcBtnSize}
        mobileSize={mobilBtnSize}
        btnRound={radiusFull}
        textSize={textSize}
        padding={padding}
        width={width}
        onClick={onClick}
        disabled={disabled}
        btnType={btnType}
        type="button"
        {...res}
      >
        {btnText || children}
      </StyledButton>
    </>
  );
};

export default Button;

const StyledButton = styled.button<{
  color?: BtnColor;
  pcSize?: BtnSize;
  mobileSize?: BtnSize;
  btnRound: boolean;
  textSize?: FlattenSimpleInterpolation;
  padding?: string;
  width?: Width;
  btnType?: BtnType;
}>`
  ${({ color }) => color && btnColorCheck(color)};
  ${({ pcSize }) => pcSize && btnSizeCheck(pcSize)};

  ${media.MOBILE} {
    ${({ mobileSize }) => mobileSize && btnSizeCheck(mobileSize)}
  }

  border-radius: ${({ btnRound }) => (btnRound ? 200 : 12)}px;
  ${({ textSize }) => textSize && textSize};
  padding: ${({ padding }) => padding && padding};
  width: ${({ width }) =>
    width && typeof width === "number" ? `${width}px` : width};

  border: 0;
  outline: 0;
  cursor: pointer;
  ${cssTemplate.commonTrasition};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ btnType }) => btnType && btnTypeCheck(btnType)};
`;

const btnSizeCheck = (size: BtnSize) => {
  switch (size) {
    case "extra": {
      return css`
        padding: 16px 24px;
        ${typo.BODY_06M};
      `;
    }
    case "lager": {
      return css`
        padding: 12px 20px;
        ${typo.BODY_06M};
      `;
    }
    case "medium": {
      return css`
        padding: 12px 16px;
        ${typo.BODY_08M};
      `;
    }
    case "regular": {
      return css`
        padding: 8px 12px;
        ${typo.BODY_08M};
      `;
    }
    case "small": {
      return css`
        padding: 7px 10px;
        ${typo.BUTTON_04B};
      `;
    }
    default: {
      return;
    }
  }
};

const btnColorCheck = (color: BtnColor) => {
  switch (color) {
    case "deepGray": {
      return css`
        background: ${colors.GRAY_03};
        color: ${colors.WHITE};

        &:hover {
          background: ${colors.GRAY_02};
        }
        &:active {
          background: ${colors.GRAY_01};
        }
        &:focus {
        }
        &:disabled {
          background: ${colors.GRAY_08};
        }
      `;
    }
    case "lightGray": {
      return css`
        background: ${colors.GRAY_09};
        color: ${colors.GRAY_04};

        &:hover {
          background: ${colors.GRAY_08};
        }
        &:active {
          background: ${colors.GRAY_07};
        }
        &:focus {
        }
        &:disabled {
          background: ${colors.GRAY_09};
          color: ${colors.GRAY_07};
        }
      `;
    }
    case "primary": {
      return css`
        background: ${colors.PRIMARY};
        color: ${colors.WHITE};

        &:hover {
          background: ${colors.PRIMARY_02};
        }
        &:active {
          background: ${colors.PRIMARY_01};
        }
        /* &:focus {

        } */
        &:disabled {
          background: ${colors.PRIMARY_05};
          cursor: default;
        }
      `;
    }
    case "black": {
      return css`
        background: ${colors.GRAY_02};
        color: ${colors.WHITE};

        &:hover {
        }
        &:active {
        }
        &:focus {
        }
      `;
    }
    case "white": {
      return css`
        background: ${colors.WHITE};
        color: ${colors.GRAY_02};
      `;
    }
    default: {
      return;
    }
  }
};

const btnTypeCheck = (type: BtnType) => {
  switch (type) {
    case "line": {
      return css`
        border: 1px solid ${colors.PRIMARY};
        background: ${colors.WHITE};
        color: ${colors.PRIMARY};
      `;
    }
  }
};
