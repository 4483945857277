import React from "react";
import styled from "styled-components";

interface IconProps {
  backgroundSrc: string;
  width?: string | number;
  height?: string | number;
  onClick?: () => void | React.Dispatch<React.SetStateAction<boolean>>;
}

const Icon: React.FC<IconProps> = (props, res) => {
  return <StyledIcon onClick={props.onClick} {...props} {...res} />;
};

export default Icon;

const StyledIcon = styled.div<IconProps>`
  width: ${({ width }) => (width ? width : 40)}px;
  height: ${({ height }) => (height ? height : 40)}px;
  background: no-repeat center/contain
    url(${({ backgroundSrc }) => (backgroundSrc ? backgroundSrc : "")});

  display: flex;
  align-items: center;
  justify-content: center;
`;
