import CommonWrap from "@components/atoms/CommonWrap";
import Text from "@components/atoms/Text";
import QnaCard from "@components/molecule/QnaCard";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import TitleWrap from "@components/TitleWrap";
import { qnaOrder } from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { useIntersecting } from "@hooks/useIntersecting";
import useInterval from "@hooks/useInterval";
import { SubTitle, Title } from "@styles/CommonStyled";
import {
  colors,
  cssTemplate,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import DownloadFormModal from "../DownloadFormModal";
import RecruitingModal from "../RecruitingModal";

interface HomeSection10Props extends AnimationCommonProps {
  ref?: MutableRefObject<any>;
}

const HomeSection10 = forwardRef<any, HomeSection10Props>((props, ref) => {
  const { id } = props;
  const { ref: sectionRef, isIntersecting: isTitleIntersecting } =
    useIntersecting({
      options: { threshold: 0.8 },
    });
  const { ref: cardListRef, isIntersecting: isCardListIntersecting } =
    useIntersecting({
      options: { threshold: 0.4 },
    });

  const [isRecruitingModal, setIsRecruitingModal] = useState<boolean>(false);
  const [isDownloadFormModal, setIsDownloadFormModal] = useState(false);
  const [isTitleAnimation, setIsTitleAnimation] = useState<boolean>(false);

  const openRecruitingModal = () => {
    setIsRecruitingModal(true);
  };

  const closeRecruitingModal = () => {
    setIsRecruitingModal(false);
  };

  const openDownloadFormModal = () => {
    setIsDownloadFormModal(true);
  };

  const closeDownloadFormModal = () => {
    setIsDownloadFormModal(false);
  };

  useEffect(() => {
    if (isTitleIntersecting) setIsTitleAnimation(true);
  }, [isTitleIntersecting]);

  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isCardListIntersecting
      ? isInteracted > qnaOrder.length
        ? null
        : 150
      : null,
  );
  return (
    <>
      <StyledBaseSection ref={ref} fullWidth flexCenter>
        <StyledCommonWrap>
          <StyledTitleWrap
            id={id}
            ref={sectionRef}
            theme={{ isAnimation: isTitleAnimation }}
          >
            <SubTitle type="h3">찾아 오시는 길</SubTitle>
            {/* <ScreenResizing
              pc={
                <StyledTitle type="h2" whiteSpace="pre-line">
                  {`전기차 충전기 설치는\n믿을 수 있는 에버온에 문의하세요`}
                </StyledTitle>
              }
              tablet={
                <StyledTitle type="h2" whiteSpace="pre-line">
                  {`전기차 충전기 설치는\n믿을 수 있는 에버온에 문의하세요`}
                </StyledTitle>
              }
              mobile={
                <StyledTitle type="h2" whiteSpace="pre-line">
                  {`전기차 충전기 설치는\n믿을 수 있는\n에버온에 문의하세요`}
                </StyledTitle>
              }
            /> */}

            <ScreenResizing
              pc={
                <Description
                  size={typo.BODY_06R}
                  color={colors.GRAY_04}
                  whiteSpace="pre-line"
                >{`자차로 오실 경우 정안IC에서 나오신 후 정안면을 지나 왼쪽으로 들어 오시면 되며, 신율농장 팻말을 따라 오시면 됩니다`}</Description>
              }
              tablet={
                <Description
                  size={typo.BODY_06R}
                  color={colors.GRAY_06}
                  whiteSpace="pre-line"
                >{`자차로 오실 경우 정안IC에서 나오신 후 정안면을 지나 왼쪽으로 들어 오시면 되며, 신율농장 팻말을 따라 오시면 됩니다`}</Description>
              }
              mobile={
                <Description
                  size={typo.BODY_09R}
                  color={colors.GRAY_06}
                  whiteSpace="pre-line"
                  wordBreak="keep-all"
                >{`자차로 오실 경우 정안IC에서 나오신 후 정안면을 지나 왼쪽으로 들어 오시면 되며, 신율농장 팻말을 따라 오시면 됩니다`}</Description>
              }
            />
            <a href = "https://map.naver.com/p/directions/-/14149689.6743313,4383255.971381,%EC%8B%A0%EC%9C%A8%EB%86%8D%EC%9E%A5,12946635,PLACE_POI/-/car?c=14.00,0,0,0,dh">
              <img src="/images/map.webp"  alt="naver Map" />
            </a>
          </StyledTitleWrap>

          {/* <CardListWrap ref={cardListRef}>
            {qnaOrder.map((data, index) => {
              return (
                <QnaCard
                  key={data.title}
                  data={data}
                  handleRecruitingModalOpen={openRecruitingModal}
                  handleDownloadFormModalOpen={openDownloadFormModal}
                  isAnimation={isInteracted >= index}
                />
              );
            })}
          </CardListWrap> */}
        </StyledCommonWrap>
      </StyledBaseSection>

      {/* 문의 작성 모달 */}
      {/* <RecruitingModal
        isModalOpen={isRecruitingModal}
        handleModalClose={closeRecruitingModal}
      /> */}

      {/* 정부 보조금 다운로드 모달 */}
      {/* <DownloadFormModal
        isModalOpen={isDownloadFormModal}
        handleModalClose={closeDownloadFormModal}
      /> */}
    </>
  );
});

HomeSection10.displayName = "HomeSection10";

export default HomeSection10;

const StyledBaseSection = styled(BaseSection)`
  background: ${colors.GRAY_11};
  overflow: hidden;
  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
    height: 100%;
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  gap: 80px;

  position: relative;

  ${media.TABLET} {
  }

  ${media.MOBILE} {
    gap: 60px;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.MOBILE} {
    gap: 8px;
  }
`;

const StyledTitle = styled(Title)`
  ${media.MOBILE} {
    ${typo.HEADING_01}
  }
`;

const Description = styled(Text)``;

const CardListWrap = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;

  ${media.TABLET} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 24px;
  }
  ${media.MOBILE} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
`;
