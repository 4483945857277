import Text from "@components/atoms/Text";
import TitleWrap from "@components/TitleWrap";
import styled, { css } from "styled-components";
import { colors, media, typo } from "..";

export const Title = styled(Text)<{ textCenter?: boolean }>`
  ${typo.DISPLAY_03};
  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `}

  ${media.TABLET} {
    ${typo.DISPLAY_04};
  }
  ${media.MOBILE} {
    ${typo.HEADING_01};
  }
`;

export const SubTitle = styled(Text)<{ textCenter?: boolean }>`
  color: ${colors.PRIMARY};
  ${typo.HEADING_04};

  ${({ textCenter }) =>
    textCenter &&
    css`
      text-align: center;
    `}

  ${media.MOBILE} {
    ${typo.HEADING_09};
  }
`;

/**
 *  * `gap : x , y`
 */
export const ContentsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const ModalTitle = styled(Text)`
  ${typo.DISPLAY_05};

  ${media.TABLET} {
    ${typo.HEADING_01}
  }
`;

export const ModalDescription = styled(Text)`
  color: ${colors.GRAY_06};
  ${typo.BODY_04R};

  ${media.TABLET} {
    ${typo.BODY_06R}
  }
`;

export const ModalTitleWrap = styled(TitleWrap)`
  gap: 8px;
`;

/**
 * @param transFlexDirection?: "row" | "column";
 * @param gap?: number;
 */
export const ColumnWrap = styled.div<{
  transFlexDirection?: "row" | "column";
  gap?: number;
}>`
  display: flex;
  align-items: center;
  flex-direction: ${({ transFlexDirection }) => {
    if (transFlexDirection) return transFlexDirection;
    else return "column";
  }};

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;
